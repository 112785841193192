/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";
import countryList from 'react-select-country-list'
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import homebanner from "assets/img/homebanner.webp";
import realestatesolutions from "assets/img/imagess/realestatesolutions.webp";
import VRtour from "assets/img/imagess/VRtour.webp";
import drendering from "assets/img/imagess/drendering.webp";
import masterplan from "assets/img/imagess/masterplan.webp"; 
import brouchure from "assets/img/brouchure.png"; 
import VideoPlayer from "./VideoPlayer";


import logo1 from "assets/img/imagess/logo1.webp";
import logo2 from "assets/img/imagess/logo2.webp";
import logo3 from "assets/img/imagess/logo3.webp";
import logo4 from "assets/img/imagess/logo4.webp";
import logo5 from "assets/img/imagess/logo5.webp";
import logo6 from "assets/img/imagess/logo6.webp";
import logo7 from "assets/img/imagess/logo7.webp";





export default function Home() {

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true);
        // Open the link when the play button is clicked
        window.open('/villa/Villa.html', '_blank');
    };


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCountryError(country.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

                toast.success("Form Submitted Successfully! Thank you for reaching out to us!", {
                    position: toast.POSITION.CENTER_CENTER,
                    autoClose: 3000, // Automatically close after 3 seconds
                    hideProgressBar: false, // Show progress bar
                    closeOnClick: false, // Don't close on click
                    pauseOnHover: false, // Don't pause on hover
                    draggable: false, // Disable dragging
                    progress: undefined, // Use the default progress bar
                    bodyClassName: "toast-success" // Custom class for toast body
                });

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');

            // Reset the form
            e.target.reset();
        }
    };


    jQuery(function ($) {

        // Function which adds the 'animated' class to any '.animatable' in view
        var doAnimations = function () {

            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $animatables = $('.animatable');

            // Unbind scroll handler if we have no animatables
            if ($animatables.length == 0) {
                $(window).off('scroll', doAnimations);
            }

            // Check all animatables and animate them if necessary
            $animatables.each(function (i) {
                var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
                    $animatable.removeClass('animatable').addClass('animated');
                }
            });

        };

        // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
        $(window).trigger('scroll');

    });


    return (
        <>
            <IndexNavbarr fixed />
            <section className="w-full" style={{
                backgroundImage: `url(${homebanner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}>
                <div className="container xl:px-0 lg:px-10 mx-auto items-center xl:py-32 py-24" >
                    <div className='w-full xl:py-32'>
                        <div className='flex flex-wrap xl:py-12'>

                            <div className='xl:w-8/12  xl:mb-12'>
                                <h2 className='block animatable bounceInRight xl:text-6xl lg:text-3xl text-2xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    3D <span className='text-white'>Visualisation</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="container mx-auto mt-6">
                <div className="flex flex-wrap w-full">
                    <div className="xl:w-6/12">
                        <h3 className="text-black xl:text-7xl fontfamily font-medium   uppercase">
                            FRANKLIN<br></br>
                            GARDENS
                        </h3>
                        <p className="text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className=" align-left w-20 h-32" alt="" />
                    </div>
                </div>
            </section>


            <section className="container mx-auto mt-6">
                <div className="flex flex-wrap w-full items-end">
                    <div className="xl:w-6/12">
                        <h3 className="slide-right text-black xl:text-7xl fontfamily font-medium   uppercase">
                            RESIDENTIAL <br></br>GREEN<br></br>
                            BUILDING
                        </h3>
                        <p className="slide-right text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className=" align-left w-20 h-32" alt="" />
                    </div>
                    <div className="xl:w-6/12">
                        <img src="https://i.ibb.co/JtCryTq/archywave2.jpg" alt="" className="slide-left" />
                    </div>
                </div>
            </section>

            <section className="container mx-auto mt-32">
                <div className="w-full">
                    <div className="xl:w-12/12">
                        <img src="https://i.ibb.co/g9sVScZ/archywave1.png" alt="" className=" w-full h-80" />
                    </div>
                    <div className="xl:w-12/12 mt-8">
                        <h3 className="text-black xl:text-7xl fontfamily font-medium   uppercase">
                            GREEN-WATER<br></br>
                            OPEN AIR THEATRE
                        </h3>
                        <p className="text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className=" align-left w-20 h-32" alt="" />
                    </div>
                </div>
            </section>

            <section className="container mx-auto mt-2 xl:mb-32">
                <div className="flex flex-wrap w-full items-end">
                    <div className="xl:w-6/12">
                        <h3 className="slide-right text-black xl:text-7xl fontfamily font-medium   uppercase">
                            NY ART <br></br>MUSEUM<br></br>
                            BRIDGE
                        </h3>
                        <p className="slide-right text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className="align-left w-20 h-32" alt="" />
                    </div>
                    <div className="xl:w-6/12">
                        <img src="https://i.ibb.co/c20tvf9/archywave4.jpg" alt="" className="slide-left h-80" />
                    </div>
                </div>
            </section> */}






            <section className="container mx-auto xl:mt-12 mt-6 xl:px-12 px-4">
                <div className="w-full ">
                    <div className="flex flex-wrap flex-col-reverse xl:flex-col-reverse lg:flex-col-reverse">
                        <div className="xl:w-6/12 lg:w-6/12 block animatable bounceInLeft xl:mt-0 mt-6">
                            <img src={realestatesolutions} alt="" className=" rounded-lg slide-right "></img>
                        </div>
                        <div className="xl:w-6/12 lg:w-6/12 xl:pl-12 lg:pl-12">
                            <h2 className="items-center flex block animatable bounceInRight fontfamily xl:text-8xl text-5xl font-semibold" style={{ color: '#DE0A26' }} >
                                01

                                <span className="pl-4 mt-2 block animatable bounceInRight fontfamily xl:text-4xl text-2xl font-semibold text-black" >
                                    Real Estate Solutions
                                </span>
                            </h2>
                            <p className="block animatable bounceIn text-black fontfamily text-justify lines xl:text-sm text-sm">
                                When it comes to promotional marketing materials for Off-plan Real Estate,
                                Archywave team is ready to embody all your ideas. Given the fact that
                                versatile advertising campaign for real estate requires flexibility of solutions,
                                we developed a number of package offers, which will help you to find a
                                holistic approach to the choice of advertising materials. From the minimal
                                package to the wide complex one – our proposals include the following services:
                            </p>

                            <ul className="block animatable bounceInRight list-disc slide-left pl-4 pt-2">
                                <li className="fontfamily xl:text-sm text-sm">
                                    VR tours for web/mobile
                                </li>
                                <li className="fontfamily xl:text-sm pt-2 text-sm">
                                    Fly-through animations
                                </li>
                                <li className="fontfamily xl:text-sm pt-2 text-sm">
                                    Real-time rendering applications Unreal Engine/Unity
                                </li>
                                <li className="fontfamily xl:text-sm pt-2 text-sm">
                                    Interactive masterplan applications
                                </li>
                                <li className="fontfamily xl:text-sm pt-2 text-sm">
                                    Real estate sales app
                                </li>
                                <li className="fontfamily xl:text-sm pt-2 text-sm">
                                    Computer generated images/renders
                                </li>
                                <li className="fontfamily xl:text-sm pt-2 text-sm">
                                    Landing page development
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className=" xl:mt-12 xl:px-12 mt-6 px-4">
                <div className="container mx-auto xl:py-12 xl:px-12 ">
                    <div className="w-full ">
                        <div className="flex flex-wrap items-center">
                            <div className="xl:w-6/12 lg:w-6/12">
                                <h2 className="items-center flex block animatable bounceInRight fontfamily xl:text-8xl text-5xl font-semibold" style={{ color: '#DE0A26' }} >
                                    02

                                    <span className="pl-4 mt-2 block animatable bounceInLeft fontfamily xl:text-4xl text-2xl font-semibold text-black" >
                                        VR Tour For Web/Mobile
                                    </span>
                                </h2>
                                {/* <h1 className="block animatable bounceInLeft fontfamily xl:text-4xl font-semibold text-black">
                                    VR Tour For Web/Mobile
                                </h1> */}
                                <p className="block animatable bounceInLeft text-black fontfamily text-justify  lines xl:text-sm text-sm">
                                    Let your buyer look around an apartment. Let them see the interior, look
                                    inside of each room, outside the window and enjoy the view (even if the
                                    building is not finished yet, the client will be able to see the exact same
                                    view which will be in their future dwelling). It is an incredibly informative
                                    tool which reveals all the prospects. Virtual Reality Tour for off-plan real
                                    estate is like an excursion and a mini game for your client. And who doesn’t
                                    like games?
                                </p>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 xl:pl-12 lg:pl-12 block animatable bounceInRight xl:mt-0 mt-6">
                                <div>
                                    {!isPlaying && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '53%',
                                                transform: 'translate(-50%, -50%)',
                                                cursor: 'pointer',
                                                zIndex: '1', // Ensure the play icon stays above the background image
                                            }}
                                            onClick={handlePlay}
                                        >
                                            <img src="https://i.ibb.co/d2QB4Cm/youtube.png" alt="Play" style={{ width: '60px', height: '60px' }} />
                                        </div>
                                    )}
                                    <div className=""
                                        style={{
                                            width: '100%',
                                            height: '350px',
                                            backgroundImage: `url(${VRtour})`, // Add your background image URL here
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            borderRadius: '5px',
                                            zIndex: '-1', // Ensure the background image stays behind the play icon

                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            <section className=" xl:px-12 px-4 xl:mt-0 mt-6">
                <div className="container mx-auto xl:py-12 xl:px-12 ">
                    <div className="w-full ">
                        <div className="flex flex-wrap items-center xl:flex-col-reverse lg:flex-col-reverse flex-col-reverse">
                            <div className="xl:w-6/12 lg:w-6/12 block animatable bounceInLeft xl:mt-0 mt-6">
                                {/* <iframe className="xl:w-12/12 h-50 rounded-lg"
                                    src="https://www.youtube.com/embed/1PqewoipdLo?si=VzIkFBVhVOWLC6nb">
                                </iframe> */}
                                <VideoPlayer sourceLink="https://fast.wistia.net/embed/iframe/plvnbq7pvq?seo=true&videoFoam=true"/>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 xl:pl-12 lg:pl-12">
                                <h1 className="items-center flex block animatable bounceInRight fontfamily xl:text-8xl text-5xl font-semibold" style={{ color: '#DE0A26' }} >
                                    03
                                    <span className="pl-4 mt-2 block animatable bounceInLeft fontfamily xl:text-4xl text-2xl font-semibold text-black" >
                                        Real-Time Rendering Applications
                                    </span>
                                </h1>
                                {/* <h3 className=" mt-2 block animatable bounceInRight fontfamily xl:text-2xl text-black">
                                    (Oculus Rift, HTC Vive Etc.)
                                </h3> */}
                                {/* <h1 className="block animatable bounceInRight fontfamily xl:text-4xl font-semibold text-black" >
                                    Real-Time Rendering Applications <br></br><span className="xl:text-3xl" style={{ color: '#3d5975' }}>
                                        (Oculus Rift, HTC Vive Etc.)</span>
                                </h1> */}
                                <p className="block animatable moveUp text-black fontfamily text-justify mt-4 lines xl:text-sm text-sm">
                                    Real-time rendering is not new – it has been used in the gaming industry
                                    for quite a long time but now we can surely say that it has also become
                                    one of the most important parts of the world of real estate.
                                </p>
                                <p className="block animatable moveUp text-black fontfamily text-justify mt-4 lines xl:text-sm text-sm">
                                    Real estate developers from all over the world pay close attention
                                    to this technology, because it actually allows visiting a property
                                    before selling or buying it, which proved to be beneficial for both
                                    property developers and their clients. But let us have a look at how all
                                    of this magic is happening.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section className="container mx-auto xl:mt-12 mt-6 xl:px-12 px-4">
                <div className="w-full ">
                    <div className="flex flex-wrap items-center">
                        <div className="xl:w-6/12 lg:w-6/12">
                            <h1 className="items-center flex block animatable bounceInRight fontfamily xl:text-8xl text-5xl font-semibold" style={{ color: '#DE0A26' }} >
                                04
                                <span className="pl-4 mt-2 block animatable bounceInLeft fontfamily xl:text-4xl text-2xl font-semibold text-black" >
                                    Fly-Through Animation
                                </span>

                            </h1>
                            <p className="block animatable fadeInDown text-black fontfamily text-justify lines xl:text-sm text-sm">
                                3D walk-through animation could be either a wonderful addition to
                                marketing materials, or an excellent individual tool to represent your
                                real estate. Well-chosen combination of picture, music and information block
                                will give the brightest impressions from the very first seconds of watching.
                                Motion always grabs attention: it has all that life and dynamics in it.
                                With the help of a short commercial you will be able to be succinct
                                yet informative in your way of reaching your potential client.
                            </p>

                            {/* <div className="xl:w-3/12 text-center bg-red-900 text-white mt-8 xl:text-base py-2 rounded ">
                                View More
                            </div> */}
                        </div>
                        <div className="xl:w-6/12 lg:w-6/12 block animatable bounceInRight xl:pl-12 lg:pl-12 xl:mt-0 mt-6">
                            {/* <iframe className="xl:w-12/12 h-50 rounded-lg"
                                src="https://www.youtube.com/embed/Ztyfmztgv3g?si=xXtsF4cuuT4uTQQd">
                            </iframe> */}
                            <VideoPlayer sourceLink="https://fast.wistia.net/embed/iframe/cbwhicgg86?seo=true&videoFoam=true"/>
                        </div>

                    </div>
                </div>
            </section>


            <section className=" xl:mt-12 xl:px-12 mt-6 px-4">
                <div className="container mx-auto xl:py-12 xl:px-12">
                    <div className="w-full ">
                        <div className="flex flex-wrap items-center  xl:flex-col-reverse lg:flex-col-reverse flex-col-reverse">
                            <div className="xl:w-6/12  lg:w-6/12 block animatable bounceInLeft xl:mt-0 mt-6">
                                <img src={drendering} alt="" className="rounded-lg"></img>
                            </div>
                            <div className="xl:w-6/12 lg:w-6/12 xl:pl-12 lg:pl-12 ">
                                <h1 className="items-center flex block animatable bounceInRight fontfamily xl:text-8xl text-5xl font-semibold" style={{ color: '#DE0A26' }} >
                                    05
                                    <span className="pl-4 mt-2 block animatable bounceInLeft fontfamily xl:text-4xl text-2xl font-semibold text-black" >
                                        3D Rendering
                                    </span>

                                </h1>
                                {/* <h1 className="block animatable bounceInRight fontfamily xl:text-4xl font-semibold text-black" >
                                    3D Rendering
                                </h1> */}
                                <p className="block animatable bounceInRight text-black fontfamily text-justify lines xl:text-sm text-sm">
                                    When we speak about render, we immediately think of a static image of an
                                    interior or exterior fully generated with a computer. CGI is a convenient
                                    advertising tool for your product, no matter what development phase it is in.
                                    Renders have indisputable advantages.
                                </p>
                                <p className="block animatable bounceInRight text-black fontfamily text-justify lines xl:text-sm text-sm">
                                    Firstly, affordable price Without
                                    spending too much, you will be able to provide yourself with materials
                                    which can be used as a marketing tool for social networks, as promotional
                                    materials for outdoor advertising and practically everywhere you want the
                                    received images to be used. Secondly, the speed of execution. In comparison
                                    with video or virtual tours, 3D visualization is considered to be the
                                    fastest way to get quality materials for work and advertising. Thirdly,
                                    super realistic visuals. A good render will be hard to distinguish from
                                    a photograph.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="container mx-auto xl:mt-12 xl:px-12 mt-6 px-4 xl:flex-col-reverse flex-col-reverse">
                <div className="w-full ">
                    <div className="flex flex-wrap items-center">

                        <div className="xl:w-6/12 lg:w-6/12">
                            <h1 className="items-center flex block animatable bounceInRight fontfamily xl:text-8xl text-5xl font-semibold" style={{ color: '#DE0A26' }} >
                                06
                                <span className="pl-4 mt-2 block animatable bounceInLeft fontfamily xl:text-4xl text-2xl font-semibold text-black" >
                                    Interactive Masterplan Applications
                                </span>

                            </h1>
                            {/* <h1 className="block animatable bounceInLeft fontfamily xl:text-4xl font-semibold text-black" >
                                Interactive Masterplan Applications
                            </h1> */}
                            <p className="block animatable bounceInLeft text-black fontfamily text-justify  lines xl:text-sm">
                                Interactive 3D Masterplan is a new take on the ideal performance of real
                                estate. If you work in real estate – whether you are a developer, or an
                                architect or any other player on the market – Interactive 3D Masterplan
                                is a great flexible and multifunctional tool for you. It is indispensable
                                as informational presentation for investors and a brilliant marketing
                                assistant during the stage of sales. It is not just a picture or a panorama – a
                                spectator is literally becoming part of the space.
                            </p>
                        </div>
                        <div className="xl:w-6/12 lg:w-6/12 block animatable bounceRight xl:pl-12 lg:pl-12 xl:mt-0 mt-6">
                            <img src={masterplan} alt="" className="rounded-lg"></img>
                        </div>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 xl:mt-12 mt-12">
                <div className="container mx-auto xl:py-12 py-6">
                    <div className="w-full">
                        <h2 className="fontfamily xl:text-4xl lg:text-3xl text-3xl font-semibold  text-center text-black ">
                            Clients
                        </h2>
                        <div className="flex flex-wrap justify-center items-center  xl:py-12">
                            <div className="xl:mt-0 mt-6">
                                <img src={logo1} alt="" className="xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src={logo2} alt="" className="xl:w-44 w-32 block animatable fadeInDown" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src={logo3} alt="" className="xl:w-44 w-32 block animatable fadeInUp" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src={logo4} alt="" className="xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>


                            <div className="xl:mt-0 mt-6">
                                <img src={logo5} alt="" className="xl:w-44 w-32 xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src={logo6} alt="" className="xl:w-44 w-32 xl:w-44 w-32 block animatable fadeInUp" />
                            </div>

                            <div className="xl:mt-0 mt-6">
                                <img src={logo7} alt="" className="xl:w-44 w-32 block animatable bounceInRight" />
                            </div>
                        </div>
                    </div>
                    <div className="flex mx-auto justify-center -mt-4">
                        <a href="/projects">
                            <button className="text-center px-4 py-2 xl:mt-0 mt-12 rounded  bg-red-900 text-white mt-6 fontfamily  xl:text-sm" type="submit">
                                View More <i className="fa fa-arrow-right pl-4"></i>
                            </button>
                        </a>
                    </div>
                </div>
            </section>



            <section className="bg-black">
                <section className="container mx-auto xl:px-12 xl:py-6 py-6 px-4 ">
                    <div className="xl:px-12 w-ful ">
                        <div className="flex flex-wrap xl:py-12 items-center">
                            <div className="xl:w-6/12 w-12/12  lg:w-6/12">
                                <h3 className="fontfamily xl:text-2xl text-3xl text-white font-semibold">
                                    Get In Touch
                                </h3>
                                <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                    <div className="flex flex-wrap">
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                            {nameError && firstName.length === 0 ?
                                                <label className="error">Enter Your First Name</label> : ""}
                                        </div>
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                            {nameeError && lastName.length === 0 ?
                                                <label className="error">Enter Your Last Name</label> : ""}
                                        </div>
                                    </div>

                                    <div className="px-2 xl:w-12/12 xl:mt-6 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                        {emailError && email.length === 0 ?
                                            <label className="error">Enter Your Email</label> : ""}
                                        {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                            <label className="error">Please Enter Valid Email</label> : ""}


                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <select
                                            id="country"
                                            name="country"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            className={`xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${countryError ? 'border-red-500' : ''}`}
                                        >
                                            <option value="">Select your country</option>
                                            {countryOptions.map((option) => (
                                                <option key={option.value} value={option.label}>{option.label}</option>
                                            ))}
                                        </select>
                                        {countryError && <p className="error">Please select your country.</p>}
                                    </div>


                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <input
                                            type="tel"
                                            value={tel}
                                            onChange={(e) => setTel(e.target.value)}
                                            placeholder="Phone Number"
                                            name="tel"
                                            className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                        />
                                        {telError && <label className="error pt-2">Phone Number is required</label>}
                                        {telregexError && <label className="error pt-2">Please enter a valid phone number</label>}

                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                        {messageError && message.length === 0 ?
                                            <label className="error">Please Enter Your Message</label> : ""}
                                    </div>
                                    <div className="items-center text-center justify-center">
                                        <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                                <ToastContainer position="top-right" />
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12  lg:pl-12 xl:pl-12 xl:mt-0 mt-12">
                                <img src={brouchure} alt="" className="rounded-lg"></img>
                            </div>
                        </div>

                    </div>
                </section>
            </section>

            <Footer />
        </>
    );
}
