
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";
import countryList from 'react-select-country-list'
import servicebanner from "assets/img/servicesbanner.webp";
import animation from "assets/img/imagess/animation.webp";
import visualization from "assets/img/imagess/visualization.webp";
import realestateapp from "assets/img/imagess/realestateapp.webp";
import scalemodal from "assets/img/imagess/scalemodal.webp";
import floorplan from "assets/img/imagess/floorplan.webp";

import stimulator from "assets/img/imagess/stimulator.webp";
import vr from "assets/img/imagess/vr.webp";
import masterplanss from "assets/img/imagess/masterplanss.webp";
import realtimerendering from "assets/img/imagess/realtimerendering.webp";
import photomontage from "assets/img/imagess/photomontage.webp";

import archywavebanner from "assets/img/imagess/archywavebanner.webp";


export default function Services() {


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCountryError(country.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            alert("Form Submitted Successfully");

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');

            // Reset the form
            e.target.reset();
        }
    };


    return (
        <>
            <IndexNavbarr fixed />




            <section className="w-full" style={{
                backgroundImage: `url(${servicebanner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}>
                <div className="container mx-auto items-center xl:py-32" >
                    <div className='w-full xl:py-32 py-16'>
                        <div className='pt-12'>

                            <div className='xl:w-12/12  w-full mb-12'>
                                <h2 className=' xl:text-6xl text-3xl pl-3  uppercase text-left fontfamily1 font-semibold' style={{ color: '#DE0A26' }}>
                                    Our <span className='text-white mt-4'>
                                        Services</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w-full bg-black py-32">

                <div className=" mx-auto">
                    <div className="">
                        <div className="flex flex-wrap mt-6 justify-center">

                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 h-80 image-container1" style={{ backgroundImage: `url(${animation})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/ZJmwxqx/3-D-animation.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-3xl text-center px-4 mt-4">
                                        3D <br></br>Animation
                                    </h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 h-80 image-container1" style={{ backgroundImage: `url(${visualization})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/XkY04g0/3-D-visualization.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-3xl text-center px-4 mt-4">
                                        3D Visualisation
                                    </h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 xl:mt-0 mt-12 lg:mt-0 h-80 image-container1" style={{ backgroundImage: `url(${floorplan})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/q9tNHFn/floor-plan.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-3xl text-center px-4 mt-4">
                                        3D <br></br>Floor Plan
                                    </h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 xl:mt-0 mt-12 lg:mt-0 h-80 image-container1" style={{ backgroundImage: `url(${scalemodal})`, backgroundPosition: "right", backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/cy0T0qf/scale-model-1.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-3xl text-center px-4 mt-4">
                                        Scale <br></br>Model
                                    </h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 xl:mt-0 mt-12 lg:mt-0 h-80 image-container1" style={{ backgroundImage: `url(${realestateapp})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/mG3vRNp/real-estate-sale-app.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-3xl text-center px-4 mt-4">

                                        Real Estate Sales App
                                    </h2>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className=" mx-auto xl:mt-32 mt-32">
                    <div className="xl:px-2">
                        <div className="flex flex-wrap mt-6 justify-center">

                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 px-4 h-80 image-container1" style={{ backgroundImage: `url(${stimulator})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/vwwDQNF/3-D-stimulator-for-pc.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-2xl text-left px-4 mt-4 text-center">3D Stimulator for pc</h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 px-4 h-80 image-container1" style={{ backgroundImage: `url(${vr})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/V328cdp/virtual-reality-for-real-estate.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-2xl text-left px-4 mt-4 text-center">Virtual Reality for Real Estate</h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 xl:mt-0 mt-12 lg:mt-0 px-12 h-80 image-container1" style={{ backgroundImage: `url(${photomontage})`, backgroundPosition: 'left', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/FWgRK4L/photomontage.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-2xl text-center px-2 mt-4 text-center">Photo <br></br>Montage</h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 mt-12 xl:mt-0 lg:mt-0 px-4 h-80 image-container1" style={{ backgroundImage: `url(${masterplanss})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/20zKGR4/intractive-master-plan.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-2xl text-left px-4 mt-4 text-center">Interactive Masterplan</h2>
                                </div>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-3/12 w-6/12 xl:mt-0 mt-12 lg:mt-0 h-80 image-container1" style={{ backgroundImage: `url(${realtimerendering})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                                <div className="xl:py-32 py-32">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/KzHYd4v/real-time-rendering.png" alt="" className="w-24 xl:mt-32 " />
                                    </div>
                                    <h2 className="fontfamily font-semibold text-white xl:text-2xl text-left px-4 mt-4 text-center">Real Time Rendering</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className=" mx-auto items-center" >
                    <div className="xl:px-20 xl:mt-12">
                        <div className="w-full px-2">
                            <h4 className="uppercase fontfamily text-white  xl:text-5xl text-3xl mt-12 text-center xl:mt-32 mb-6">
                                Services
                            </h4>
                            <div className="flex flex-wrap mt-12">
                                <div className="xl:w-6/12 lg:w-6/12 md:w-12/12 w-12/12 zoom2  xl:py-12 border border-red-800 ">
                                    {/* <h4 className="fontfamily text-white font-semibold xl:text-3xl xl:px-20">
                                        Our Services
                                    </h4> */}
                                    <ul className="xl:pl-12 list-disc xl:px-20 px-4 pl-12 xl:mt-6 mt-12">
                                        <li className=" fontfamily text-red-900 xl:text-2xl text-lg">
                                            3D Animation/ Cinematic Animation
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            Elevate your marketing with our 3D walkthrough ,Flythrough and cinematic animations.
                                            Our walkthroughs bring projects to life, offering a realistic experience
                                            that immerses prospects in the site.
                                        </p>
                                        <li className=" fontfamily text-red-900 xl:text-2xl text-lg xl:mt-12 mt-6">
                                            3D Visualization
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            3D Rendering for Interior & Exterior Designs that transform your vision with
                                            lifelike 3D renders from our CGI services. Our detailed interiors and exteriors
                                            capture your project's essence with precision and realism.
                                        </p>

                                        <li className=" fontfamily text-red-900 xl:text-2xl text-lg xl:mt-12 mt-6">
                                            3D Floor Plan
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            Visualize interiors seamlessly with our 3D floor plans. Furnishings, textures,
                                            and color schemes enhance property presentations, streamlining sales and leasing processes.
                                        </p>

                                        <li className=" fontfamily text-red-900 xl:text-2xl text-lg xl:mt-12 mt-6">
                                            Scale Model
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            Impress with our accurate scale models, mirroring every project
                                            detail in miniature form. Explore projects thoroughly without needing to travel,
                                            showcasing excellence effortlessly.
                                        </p>

                                        <li className=" fontfamily text-red-900 xl:text-2xl text-lg xl:mt-12 mt-6">
                                            Real Estate Sales App
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4 mb-6">
                                            Our bespoke Real Estate Sales App revolutionizes property presentations
                                            for both launched and under-construction properties. Why limit client
                                            engagement to on-site visits? With our app's Virtual Reality tours, clients
                                            can explore properties in detail from anywhere.
                                        </p>


                                    </ul>
                                </div>


                                <div className="xl:w-6/12 lg:w-6/12 md:w-12/12 w-12/12 zoom2  xl:py-12 border border-red-800 ">
                                    <ul className="xl:pl-12 list-disc xl:px-20 px-4 pl-12 xl:mt-6">
                                        <li className=" fontfamily text-red-900 xl:text-2xl text-lg mt-6">
                                            3D Stimulator for pc
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            The 3D Simulator combines all housing projects into one app, showcasing strengths
                                            and travel times to key points. It provides a clear view of project locations,
                                            answers questions comprehensively, and aids in closing sales.
                                        </p>
                                        <li className=" fontfamily text-red-900 xl:text-2xl  text-lg xl:mt-12 mt-6">
                                            Virtual Reality for Real Estate
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            Transform real estate marketing with our VR expertise. From immersive AR and VR to
                                            captivating 360° panoramas, we create unforgettable engagements that elevate your brand.
                                        </p>

                                        <li className=" fontfamily text-red-900 xl:text-2xl  text-lg xl:mt-12 mt-6">
                                            Photomontage
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            Experience captivating real estate scenes through our photomontage service.
                                            Outstanding photographs bring properties to life, captivating viewers with a real-like feel.
                                        </p>

                                        <li className=" fontfamily text-red-900 xl:text-2xl  text-lg xl:mt-12 mt-6">
                                            Interactive Masterplan
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4">
                                            Empower your real estate team with our 3D Masterplan App. Search properties by name,
                                            location, cost, and more, enhancing sales presentations in person or remotely.
                                        </p>

                                        <li className=" fontfamily text-red-900 xl:text-2xl  text-lg xl:mt-12 mt-6">
                                            Real Time Rendering
                                        </li>
                                        <p className="text-white fontfamily xl:text-base text-sm mt-4 mb-6">
                                            Experience seamless drone shoots showcasing iconic routes and infrastructure. Our
                                            visuals guide viewers with live views of approach roads, capturing the project's
                                            charm in real time.
                                        </p>
                                    </ul>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>



                <div className="container mx-auto xl:py-32 py-24">
                    <h4 className="fontfamily text-white font-semibold xl:text-5xl lg:text-3xl text-3xl uppercase xl:px-20 text-center">
                        Why <span className="text-red-900">Choose </span> Us
                    </h4>
                    <div className="flex flex-wrap  xl:mt-12 mt-12">
                        <div className="xl:w-4/12 border border-red-200 py-4 zoom2">
                            <ul className="xl:pl-12 list-disc xl:px-20 pl-12 xl:mt-6 ">
                                <li className=" fontfamily text-white xl:text-xl text-xl">
                                    Experienced Team
                                </li>
                                <li className=" fontfamily text-white xl:text-xl text-xl mt-6">
                                    Strong in Technology
                                </li>
                            </ul>
                        </div>

                        <div className="xl:w-4/12 border border-red-200 py-4 zoom2">
                            <ul className="xl:pl-12 list-disc xl:px-20 pl-12 xl:mt-6 mt-6 lg:mt-0">
                                <li className=" fontfamily text-white xl:text-xl text-xl">
                                    Innovative Approach
                                </li>
                                <li className=" fontfamily text-white xl:text-xl text-xl mt-6">
                                    Experience in Large-Scale Projects
                                </li>
                            </ul>
                        </div>

                        <div className="xl:w-4/12 border border-red-200 py-4 zoom2">
                            <ul className="xl:pl-12 list-disc xl:px-20 xl:mt-6 lg:mt-0 mt-6 pl-12 ">
                                <li className=" fontfamily text-white xl:text-xl text-xl">
                                    Commitment to Quality
                                </li>
                                <li className=" fontfamily text-white xl:text-xl text-xl mt-6">
                                    Client-Centric Approach
                                </li>
                            </ul>
                        </div>
                    </div>



                    {/* <div className="flex flex-wrap">
                        <div className="xl:w-4/12"></div>
                        <div className="xl:w-4/12">
                            <ul className="list-disc xl:pl-20 xl:mt-6">
                                <li className=" fontfamily text-white xl:text-xl">
                                    Experienced Team
                                </li>
                                <li className=" fontfamily text-white xl:text-xl mt-6">
                                    Strong in Technology
                                </li>
                                <li className=" fontfamily text-white xl:text-xl mt-6">
                                    Innovative Approach
                                </li>
                                <li className=" fontfamily text-white xl:text-xl mt-6">
                                    Experience in Large-Scale Projects
                                </li>
                                <li className=" fontfamily text-white xl:text-xl mt-6">
                                    Commitment to Quality
                                </li>
                                <li className=" fontfamily text-white xl:text-xl mt-6">
                                    Client-Centric Approach
                                </li>
                            </ul>
                        </div>
                        <div className="xl:w-4/12"></div>
                    </div> */}



                </div>






                {/* <div className=" mx-auto items-center" >
                    <div className="xl:px-12 mt-6">
                        <div className="w-full px-2">
                            <div className="flex flex-wrap border border-red-200">
                                <div className="xl:w-3/12 border-r  border-red-200 bg-gray-500 ">
                                    <div className="fontfamily xl:text-5xl lineheight text-white text-left xl:pl-12 xl:py-32">
                                        Our <br></br>Services
                                    </div>
                                </div>
                                <div className="xl:w-3/12  xl:py-12  xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        3D Animation/ Cinematic Animation
                                    </h1>
                                    <p className="text-white font-light font-normal fontfamily xl:text-base mt-4">
                                        Elevate your marketing with our 3D walkthrough ,Flythrough and
                                        cinematic animations. Our walkthroughs bring projects to life, offering
                                        a realistic experience that immerses prospects in the site.
                                    </p>
                                </div>
                                <div className="xl:w-3/12  border-l border-r border-red-200 xl:py-12  xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        3D Visualization
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        3D Rendering for Interior & Exterior Designs that transform
                                        your vision with lifelike 3D renders from our CGI services. Our
                                        detailed interiors and exteriors capture your project's essence
                                        with precision and realism.
                                    </p>
                                </div>
                                <div className="xl:w-3/12 " style={{ backgroundImage: `url(" https://i.ibb.co/T2mQqQY/villa.jpg ")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                </div>
                            </div>
                            <div className="flex flex-wrap border-b border-l border-r border-red-200">
                                <div className="xl:w-3/12 border-red-200 border-r" style={{ backgroundImage: `url(" https://i.ibb.co/DKkBFW7/building.jpg ")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                </div>
                                <div className="xl:w-3/12  xl:py-12  xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        3D Floor Plan
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base xl:mt-12">
                                        Visualize interiors seamlessly with our 3D floor plans. Furnishings,
                                        textures, and color schemes enhance property presentations,
                                        streamlining sales and leasing processes.
                                    </p>
                                </div>
                                <div className="xl:w-3/12  xl:py-12 border-l border-r border-red-200 xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        Scale Model
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base xl:mt-12">
                                        Impress with our accurate scale models, mirroring every project detail
                                        in miniature form. Explore projects thoroughly without needing to travel,
                                        showcasing excellence effortlessly.
                                    </p>
                                </div>
                                <div className="xl:w-3/12  xl:py-12  xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        Real Estate<br></br>Sales App
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        Our bespoke Real Estate Sales App revolutionizes property
                                        presentations for both launched and under-construction properties.
                                        Why limit client engagement to on-site visits? With our app's
                                        Virtual Reality tours, clients can explore properties in detail
                                        'from anywhere
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}






                {/* <div className=" mx-auto items-center" >
                    <div className="xl:px-12 mt-6">
                        <div className="w-full px-2">
                            <div className="flex flex-wrap border border-red-200">
                                <div className="xl:w-3/12 bg-gray-500 ">
                                    <div className="fontfamily xl:text-5xl lineheight text-white text-left xl:pl-12 xl:py-32">
                                        Our <br></br>Services
                                    </div>
                                </div>

                                <div className="xl:w-3/12  xl:py-12 xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        3D Stimulator for pc
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        The 3D Simulator combines all housing projects into one app,
                                        showcasing strengths and travel times to key points. It provides
                                        a clear view of project locations, answers questions comprehensively,
                                        and aids in closing sales.
                                    </p>
                                </div>

                                <div className="xl:w-3/12  xl:py-12 border-l border-r border-red-200 xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        Virtual Reality for Real Estate
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        "Transform real estate marketing with our VR expertise. From
                                        immersive AR and VR to captivating 360° panoramas, we create
                                        unforgettable engagements that elevate your brand."

                                    </p>
                                </div>

                                <div className="xl:w-3/12 " style={{ backgroundImage: `url(" https://i.ibb.co/T2mQqQY/villa.jpg ")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                </div>

                            </div>
                            <div className="flex flex-wrap border-b border-l border-r border-red-200">
                                <div className="xl:w-3/12 border-r border-red-200" style={{ backgroundImage: `url(" https://i.ibb.co/DKkBFW7/building.jpg ")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                </div>

                                <div className="xl:w-3/12  xl:py-12  xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        Photomontage
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        Experience captivating real estate scenes through our
                                        photomontage service. Outstanding photographs bring properties
                                        to life, captivating viewers with a real-like feel.
                                    </p>
                                </div>

                                <div className="xl:w-3/12  xl:py-12 border-l border-r border-red-200 xl:px-12">
                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        Interactive Masterplan
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        Empower your real estate team with our 3D Masterplan App.
                                        Search properties by name, location, cost, and more, enhancing
                                        sales presentations in person or remotely.
                                    </p>
                                </div>

                                <div className="xl:w-3/12  xl:py-12  border-r border-red-200 xl:px-12">

                                    <h1 className="fontfamily xl:text-3xl text-red-900 text-left ">
                                        Real Time Rendering
                                    </h1>
                                    <p className="text-white fontfamily xl:text-base mt-4">
                                        Experience seamless drone shoots showcasing iconic routes and
                                        infrastructure. Our visuals guide viewers with live views of
                                        approach roads, capturing the project's charm in real time.
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div > */}







                {/* <div className="xl:px-12 mx-auto xl:py-12">
                    <div className="w-full px-2">
                        <div className="flex flex-wrap border border-red-200">
                            <div className="xl:w-3/12 border-r border-red-200 xl:px-12 ">
                                <h2 className="xl:py-16 fontfamily text-white xl:text-4xl">
                                    Why Choose <br></br> Us?
                                </h2>
                            </div>
                            <ul className="list-disc xl:w-3/12  xl:px-12 py-16">
                                <li className=" fontfamily  text-white xl:text-xl">
                                    Experienced Team
                                </li>
                                <li className="mt-6 fontfamily text-white xl:text-xl">
                                    Strong in Technology
                                </li>
                            </ul>
                            <ul className="list-disc xl:w-3/12 border-l border-r border-red-200 xl:px-12 py-16">
                                <li className=" fontfamily text-white xl:text-xl">
                                    Innovative Approach
                                </li>
                                <li className="mt-6 fontfamily text-white xl:text-xl">
                                    Experience in Large-Scale Projects
                                </li>
                            </ul>
                            <ul className="list-disc xl:w-3/12 xl:px-12 py-16">
                                <li className="fontfamily text-white xl:text-xl">

                                    Commitment to Quality
                                </li>
                                <li className="mt-6 fontfamily text-white xl:text-xl">
                                    Client-Centric Approach
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}




                <div className="w-full" style={{
                    backgroundImage: `url(${archywavebanner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    , backgroundAttachment: 'fixed'
                }}>
                    <div className="container mx-auto xl:py-32 py-32">
                        <div className="w-full  xl:px-32 lg:px-32 px-4 justify-center xl:py-32">
                            <h3 className="text-white fontfamily xl:text-2xl xl:px-32 text-center">
                                "Unlock the power of imagination with our premier 3D rendering services. At the forefront
                                of innovation, we bring your visions to life with stunning realism and precision. Whether
                                you're an architect, designer, or creative visionary, our expert team delivers high-quality,
                                custom visual solutions that elevate your projects and captivate your audience. Transform
                                ideas into breathtaking realities with us"
                            </h3>
                            <div className="mt-6 justify-center text-center web">
                                <a href="/process-and-specification">
                                    <div className="rounded font-semibold bg-red-900 xl:w-2/12 lg:w-3/12 w-6/12 py-2 text-center fontfamily xl:text-base text-white">
                                        Know More
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="container mx-auto xl:px-12 xl:mt-12 px-4 mt-12">
                    <div className="xl:px-12 w-full ">
                        <h3 className="fontfamily xl:text-3xl text-center text-3xl text-red-900 font-semibold xl:mt-32">
                            Let's Work Together
                        </h3>
                        <p className="mt-2 text-center xl:text-xl xl:px-32 fontfamily text-white">
                            Ready to take your business to the next level? Contact us today to learn
                            more about how our expertise can help you achieve your goals.
                        </p>
                        {/* <div className="flex flex-wrap mt-3 items-center">
                            <div className="xl:w-6/12 w-12/12 lg:w-6/12 ">
                                
                                <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                    <div className="flex flex-wrap">
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                            {nameError && firstName.length === 0 ?
                                                <label className="error">Enter Your First Name</label> : ""}
                                        </div>
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                            {nameeError && lastName.length === 0 ?
                                                <label className="error">Enter Your Last Name</label> : ""}
                                        </div>
                                    </div>

                                    <div className="px-2 xl:w-12/12 xl:mt-6 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                        {emailError && email.length === 0 ?
                                            <label className="error">Enter Your Email</label> : ""}
                                        {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                            <label className="error">Please Enter Valid Email</label> : ""}


                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                    <select
                                        id="country"
                                        name="country"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        className={`fontfamily shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${countryError ? 'border-red-500' : ''}`}
                                    >
                                        <option value="">Select your country</option>
                                        {countryOptions.map((option) => (
                                            <option key={option.value} value={option.label}>{option.label}</option>
                                        ))}
                                    </select>
                                    {countryError && <p className="error">Please select your country.</p>}
                                </div>
                           

                            <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                    placeholder="Phone Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                {telError && tel.length === 0 ?
                                    <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                    <label className="error pt-2">Please Enter Valid Number</label> : ""}
                            </div>

                            <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                    placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                {messageError && message.length === 0 ?
                                    <label className="error">Please Enter Your Message</label> : ""}
                            </div>
                            <div className="items-center text-center justify-center">
                                <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="xl:w-6/12 lg:w-6/12 lg:pl-12 xl:pl-12 xl:mt-0 mt-12">
                        <img src="https://i.ibb.co/g44TBCx/archywavebrouchure.jpg" alt="" className="rounded-lg"></img>
                    </div>
                </div> */}

                    </div>
                </section >






            </section >


            <Footer />
        </>
    );
}
