import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

//views new created pagess 

import Home from "views/Home.js";
import Projects from "views/Projects.js";
import Contact from "views/Contact";
import Studio from "views/Studio";
import Workflows from "views/Workflows";
import Form from "views/Form.js";
import About from "views/About.js";
import Coming from "views/Coming.js";
import Services from "views/Services";
import Process from "views/Process";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
      <Route path="/home" exact component={Home} />
      <Route path="/projects" exact component={Projects} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/packages" exact component={Studio} />
      <Route path="/workflows" exact component={Workflows} />
      <Route path="/career" exact component={Form} />
      <Route path="/about-us" exact component={About} />
      <Route path="/coming-soon" exact component={Coming} />
      <Route path="/services" exact component={Services} />
      <Route path="/process-and-specification" exact component={Process} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
