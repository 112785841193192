/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo} from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import countryList from 'react-select-country-list'
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import archycontact from "assets/img/imagess/archycontact.webp";

export default function Contact() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCountryError(country.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            alert("Form Submitted Successfully");

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');

            // Reset the form
            e.target.reset();
        }
    };


    return (
        <>
            <IndexNavbarr fixed />

            <section className="w-full" style={{
                backgroundImage: `url(${archycontact})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}>
                <div className="container mx-auto items-center xl:py-32" >
                    <div className='w-full xl:py-32 py-24'>
                        <div className='flex flex-wrap xl:py-12'>

                            <div className='xl:w-8/12  xl:px-0 px-0 lg:px-10 xl:mt-0 mt-12 mb-12'>
                                <h2 className='text-4xl xl:text-6xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    Contact <span className='text-white'>Us</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="header relative items-center flex h-80" style={{ backgroundImage: `url("https://i.ibb.co/L64bqT0/archycontact.jpg")`, backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                <div className="container mx-auto items-center mt-32" >
                    <div className='w-full'>
                        <div className='flex flex-wrap mb-12'>
                            <div className='xl:w-8/12  mb-12'>
                                <h2 className=' xl:text-6xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    Contact <span className='text-white'>Us</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section> */}


            <div className="bg-blueGray-100">
                <section className="container mx-auto  xl:px-12">
                    <div className="w-full ">
                        <div className="flex flex-wrap py-6 xl:py-12">
                            
                            <div className="xl:w-4/12  lg:w-4/12 w-12/12 px-4">
                                <div className="bg-white py-6 px-2 justify-center rounded text-center">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/mNLPyd4/opera.png" alt="" className="w-12 justify-center" />
                                    </div>
                                    <h1 className="fontfamily text-black font-semibold xl:text-3xl lg:text-2xl text-3xl mt-4">
                                        Australia
                                    </h1>
                                    <p className="fontfamily xl:text-lg font-medium" style={{ color: '#3d5975' }}>
                                        Sydney
                                    </p>
                                    <p className="fontfamily font-semibold xl:text-lg  text-lg text-black">
                                        info@acrhywave.com
                                    </p>
                                    <p className="fontfamily xl:text-lg text-lg text-black" >
                                        +61493165725
                                    </p>
                                </div>
                            </div>
                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 pt-6 xl:pt-0 lg:pt-0 px-4">
                                <div className="bg-white py-6 px-2 justify-center rounded text-center">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/YLB0mSm/archywavedubai.png" alt="" className="w-12 justify-center" />
                                    </div>
                                    <h1 className="fontfamily text-black font-semibold xl:text-3xl lg:text-2xl text-3xl mt-4">
                                        UAE
                                    </h1>
                                    <p className="fontfamily xl:text-lg font-medium" style={{ color: '#3d5975' }}>
                                        Dubai
                                    </p>
                                    <p className="fontfamily font-semibold xl:text-lg text-lg text-black">
                                        info@acrhywave.com
                                    </p>
                                    <p className="fontfamily xl:text-lg text-lg text-black" >
                                        +61493165725
                                    </p>
                                </div>
                            </div>
                           
                            <div className="xl:w-4/12  lg:w-4/12 w-12/12  xl:pt-0 pt-6 lg:pt-0 xl:px-4 px-4">
                                <div className="bg-white py-6 px-2 justify-center rounded text-center">
                                    <div className="justify-center text-center web">
                                        <img src="https://i.ibb.co/b7WsvVg/clock.png" alt="" className="w-10 justify-center" />
                                    </div>
                                    <h1 className="fontfamily text-black font-semibold xl:text-2xl lg:text-2xl text-3xl mt-4">
                                        Working Hours
                                    </h1>
                                    <p className="fontfamily xl:text-lg font-medium mt-4" style={{ color: '#3d5975' }}>
                                        Monday - Friday
                                    </p>
                                    
                                    <p className="fontfamily xl:text-lg text-lg text-black mb-6" >
                                        9.00 AM - 6.00 PM
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>




            <section className="container mx-auto xl:px-12 xl:mt-12 mt-12">
                <div className="xl:px-12 lg:px-10 px-2 w-full">
                    <div className="flex mx-auto justify-center">
                        <div className="xl:w-8/12 w-12/12">
                            <h3 className="fontfamily xl:text-lg text-lg font-semibold text-red-900 text-center" >
                                Get In Touch
                            </h3>
                            <p className="fontfamily text-black mt-4 text-2xl xl:text-4xl font-semibold text-center">
                                Drop us a line or give us a ring.
                            </p>
                            <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                    <div className="flex flex-wrap">
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                            {nameError && firstName.length === 0 ?
                                                <label className="error">Enter Your First Name</label> : ""}
                                        </div>
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                            {nameeError && lastName.length === 0 ?
                                                <label className="error">Enter Your Last Name</label> : ""}
                                        </div>
                                    </div>

                                    <div className="px-2 xl:w-12/12 xl:mt-6 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                        {emailError && email.length === 0 ?
                                            <label className="error">Enter Your Email</label> : ""}
                                        {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                            <label className="error">Please Enter Valid Email</label> : ""}


                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                    <select
                                        id="country"
                                        name="country"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        className={`xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${countryError ? 'border-red-500' : ''}`}
                                    >
                                        <option value="">Select your country</option>
                                        {countryOptions.map((option) => (
                                            <option key={option.value} value={option.label}>{option.label}</option>
                                        ))}
                                    </select>
                                    {countryError && <p className="error">Please select your country.</p>}
                                </div>
                           

                            <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                    placeholder="Phone Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                {telError && tel.length === 0 ?
                                    <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                    <label className="error pt-2">Please Enter Valid Number</label> : ""}
                            </div>

                            <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                    placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                {messageError && message.length === 0 ?
                                    <label className="error">Please Enter Your Message</label> : ""}
                            </div>
                            <div className="items-center text-center justify-center">
                                <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                        </div>
                    </div>

                </div>
            </section>


            <Footer />
        </>
    );
}
