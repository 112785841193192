/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import archywavelogo from "assets/img/archywavelogo.png";
import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import archywavelogo1 from "assets/img/archywavelogo1.png";

export default function Navbar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <nav id="navbar" className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg transparent borderb">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between py-2">
          <div className="xl:w-3/12 lg:w-3/12">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4  whitespace-nowrap uppercase"
            >
              <img src={archywavelogo1} alt="" className="xl:w-10/12 lg:w-12/12 pt-2 w-44" />
            </Link>
          </div>
          <div className="xl:w-8/12 lg:w-8/12 lg:block md:hidden hidden xl:block">
            <ul className="flex flex-wrap items-center justify-end">
              <a href="/about-us">
                <li className="text-white fontfamily xl:text-sm uppercase font-light px-12">
                  About Us
                </li>
              </a>
              <a href="/packages">
                <li className="text-white fontfamily xl:text-sm uppercase font-light px-12">
                  Packages
                </li>
              </a>
              <li className="text-white fontfamily xl:text-sm uppercase font-light px-4">
                <a href="#">Services</a>  <i class="fas fa-angle-down"></i>
                <ul>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">Virtual reality for real estate</a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">Real-time Rendering </a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">Interactive Masterplan</a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">Real Estate Sales App</a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">3D Floor Plan</a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">3D animation</a>
                    <ul>
                      <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">3D Animation for Architects</a></li>
                      <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">3d animation for interior designers</a></li>
                      <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">360 3d animation</a></li>
                    </ul>
                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">3D Visualization</a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">Photo Montage</a>

                  </li>
                  <li className="border-b"><a href="/services" className="fontfamily xl:text-xs uppercase ">Scale Model</a>

                  </li>
                  <li className=""><a href="/process-and-specification" className="fontfamily xl:text-xs uppercase ">Process and Specifications</a>

                  </li>

                </ul>
              </li>
              <a href="/projects">
                <li className="text-white fontfamily xl:text-sm uppercase font-light px-4">
                  Work
                </li>
              </a>
              <a href="/career">
                <li className="text-white fontfamily xl:text-sm uppercase font-light px-4">
                  Career
                </li>
              </a>
              <a href="/contact">
                <li className="text-white fontfamily xl:text-sm uppercase font-light px-4">
                  Contact Us
                </li>
              </a>
            </ul>

          </div>
          <div className="xl:w-1/12 lg:w-1/12 flex justify-end items-center">
            <div
              onClick={toggleSidebar}
              className="text-black fontfamily xl:text-xl font-light flex items-center cursor-pointer"
            >
              <img src="https://i.ibb.co/m467jwZ/menu1.png" alt="" className="w-8" />
            </div>
          </div>
        </div>
      </nav>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div onClick={toggleSidebar} className="close-btn">
          <img src="https://i.ibb.co/mN96B3V/closee.png" alt="" className="w-12" />
        </div>

        <div className="container mx-auto xl:px-12 px-4">
          <div className="w-full mt-24">
            <div className="flex flex-wrap">

              <div className="xl:w-9/12 w-12/12 lg:w-6/12 flex flex-wrap">
                <div className="xl:w-6/12 lg:w-6/12 w-6/12">
                  <a href="/">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-16 uppercase">
                      Home
                    </p>
                  </a>
                  <a href="/about-us">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-8 uppercase">
                      About Us
                    </p>
                  </a>
                  <a href="/packages">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-8 uppercase">
                      Packages
                    </p>
                  </a>
                  <a href="/services">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-8 uppercase">
                      Services
                    </p>
                  </a>
                </div>
                <div className="xl:w-6/12 w-6/12 ">
                  <a href="/projects">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-16 uppercase">
                      Work
                    </p>
                  </a>
                  <a href="/career">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-8 uppercase">
                      Career
                    </p>
                  </a>
                  <a href="/contact">
                    <p className="text-white fontfamily xl:text-5xl text-xl font-light mt-6 uppercase">
                      Contact Us
                    </p>
                  </a>
                </div>
              </div>

              <div className="xl:w-3/12 lg:w-6/12 mt-16">
                <p className="text-white fontfamily xl:text-2xl font-light xl:w-80">
                  <img src={archywavelogo1} alt="" className=" xl:w-80 w-52" />
                </p>
                <p className="text-white fontfamily xl:text-xl text-xl font-light mt-8">
                  +61493165725
                </p>
                <p className="text-white fontfamily xl:text-xl text-xl font-light mt-2">
                  info@archywave.com
                </p>

                <p className="text-white fontfamily xl:text-xl text-xl font-light flex  items-center mt-12">
                  Facebook <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
                <a href="https://www.linkedin.com/company/archywave-pvt-ltd/about/?viewAsMember=true" className=" mt-3">
                <p className="text-white fontfamily xl:text-xl text-xl font-light flex items-center mt-3">
                  LinkedIn <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
                </a>
                <p className="text-white fontfamily xl:text-xl text-xl font-light flex items-center mt-3">
                  Instagram <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4 "></img>
                </p>
                <a href="http://www.youtube.com/@archywavepvtltd" className="mt-3">
                <p className="text-white fontfamily xl:text-xl text-xl font-light flex items-center mt-3">
                  Youtube <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
                </a>
                <p className="text-white fontfamily xl:text-xl text-xl font-light flex items-center mt-3">
                  Behance <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar content goes here */}
      </div>
    </>
  );
}
