/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Workflows() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="header relative pt-32 items-center flex mt-32">
                
                </section>



            <Footer />
        </>
    );
}
