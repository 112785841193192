/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";
import countryList from 'react-select-country-list'

export default function Process() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCountryError(country.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            alert("Form Submitted Successfully");

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');

            // Reset the form
            e.target.reset();
        }
    };


    jQuery(function ($) {

        // Function which adds the 'animated' class to any '.animatable' in view
        var doAnimations = function () {

            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $animatables = $('.animatable');

            // Unbind scroll handler if we have no animatables
            if ($animatables.length == 0) {
                $(window).off('scroll', doAnimations);
            }

            // Check all animatables and animate them if necessary
            $animatables.each(function (i) {
                var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
                    $animatable.removeClass('animatable').addClass('animated');
                }
            });

        };

        // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
        $(window).trigger('scroll');

    });


    return (
        <>
            <IndexNavbarr fixed />

            <section className="w-full" style={{
                backgroundImage: `url("https://i.ibb.co/WtcJ4Jx/homebanner1.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}>
                <div className="container mx-auto items-center xl:py-32 py-24" >
                    <div className='w-full xl:mt-12'>
                        <div className='flex flex-wrap '>

                            <div className='xl:w-8/12  xl:mb-12'>
                                <h2 className='block animatable bounceInRight xl:text-5xl text-2xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    Process <span className='text-white'>and <br></br>Specifications</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full bg-black xl:py-12 py-6" >
                <div className="container mx-auto">
                    <h3 className="fontfamily font-semibold text-white xl:text-4xl text-2xl uppercase text-center">
                        Archywave Workflows
                    </h3>
                    <div className="flex flex-wrap px-6 xl:py-12 xl:mt-12 mt-6">
                        <div className="xl:w-6/12 xl:px-12">
                            <h4 className="text-red-900 fontfamily xl:text-2xl text-xl">
                                Rendering Workflow
                            </h4>

                            <p className="xl:mt-6 mt-4 text-white xl:text-lg text-base fontfamily font-bold">
                                1. <span className="font-light xl:text-base"> Work begins after receipt of Model Files from client. </span>
                            </p>
                            <ul className="xl:pl-12 pl-12 list-disc mt-4">
                                <li className="text-white xl:text-base fontfamily">
                                    Revit
                                </li>
                                <li className="text-white xl:text-base fontfamily py-2">
                                    SketchUp
                                </li>
                                <li className="text-white xl:text-base fontfamily">
                                    CAD drawings & elevations
                                </li>
                            </ul>

                            <p className="xl:mt-6 mt-6  text-white xl:text-lg text-base fontfamily font-bold">
                                2. <span className="font-light xl:text-base">Each Model Drafts will be sent to client for review. Client can check to ensure
                                    views and geometry are correct and provide feedback.</span>
                            </p>
                            <ul className="xl:pl-12 pl-12 list-disc mt-4">
                                <li className="text-white xl:text-base fontfamily">
                                    Renderings
                                </li>
                                <li className="text-white xl:text-base fontfamily py-2">
                                    Animations
                                </li>
                                {/* <li className="text-white xl:text-base fontfamily">
                                Unlimited Revisions
                                </li> */}
                                <li className="text-white xl:text-base fontfamily py-2">
                                    Checklist for the Stages!
                                </li>
                            </ul>

                            <p className="xl:mt-6 mt-6 text-white xl:text-lg text-base fontfamily font-bold">
                                3. <span className="font-light xl:text-base">Each Render Drafts will be sent to client for review. Client can check to ensure
                                    design details and overall style are up to expectation and provide feedback.</span>
                            </p>
                            <ul className="xl:pl-12 pl-12 list-disc mt-4">
                                <li className="text-white xl:text-base fontfamily">
                                    Renderings
                                </li>
                                <li className="text-white xl:text-base fontfamily py-2">
                                    Animations
                                </li>
                                {/* <li className="text-white xl:text-base fontfamily">
                                Unlimited Revisions
                                </li> */}
                            </ul>

                            <p className="xl:mt-6 mt-6 text-white xl:text-lg text-base fontfamily font-bold">
                                4. <span className="font-light xl:text-base">Final Rendering without ARCHYWAVE Watermark in high resolution.</span>
                            </p>
                        </div>


                        <div className="xl:w-6/12 xl:px-12 xl:mt-0 mt-12">
                            <h4 className="text-red-900 fontfamily xl:text-2xl text-xl">
                                Animation Workflow
                            </h4>

                            <p className="xl:mt-6 mt-6 text-white xl:text-lg text-base fontfamily font-bold">
                                1. <span className="font-light xl:text-base"> Modeling</span>
                            </p>
                            <p className="mt-3 text-white xl:text-base fontfamily">
                                The initial animation draft will be in low resolution with no texture or lighting,
                                and is only meant to confirm camera path/speed and model accuracy.
                            </p>

                            <p className="xl:mt-12 mt-6 text-white xl:text-lg text-base fontfamily font-bold">
                                2. <span className="font-light xl:text-base"> Rendered Key-frames Review</span>
                            </p>
                            <p className="mt-3 text-white xl:text-base fontfamily">
                                Key-frames which are still images with their textures and lighting
                                will be provided to the client for review
                            </p>

                            <p className="xl:mt-12 mt-6 text-white xl:text-lg text-base fontfamily font-bold">
                                3. <span className="font-light xl:text-base"> Animation Draft </span>
                            </p>
                            <p className="mt-3 text-white xl:text-base fontfamily">
                                Confirm everything is accurate while seeing the project in motion,
                                and make small changes to the animation
                            </p>

                            <p className="xl:mt-12 mt-6 text-white xl:text-lg text-base fontfamily font-bold">
                                4. <span className="font-light xl:text-base"> Final High-Resolution Animation Delivery </span>
                            </p>
                            <p className="mt-3 text-white xl:text-base fontfamily">
                                The final high-resolution animation will be delivered in MP4 format. For higher
                                resolutions or additional requirements, please
                                contact us for pricing and rendering times.
                            </p>
                        </div>
                    </div>
                </div>

            </section>






            <section className="bg-gray-100 ">
                <div className="container mx-auto xl:py-12 py-6">
                    <div className="w-full">
                        <h2 className="fontfamily xl:text-4xl text-3xl font-semibold  text-center text-black ">
                            Clients
                        </h2>
                        <div className="flex flex-wrap justify-center items-center  xl:py-12">
                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/9GzzWnk/lulu.pngg" alt="" className="xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/RcDMtns/hilton.png" alt="" className="xl:w-44 w-32 block animatable fadeInDown" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/s3WcmWJ/colliers.png" alt="" className="xl:w-44 w-32 block animatable fadeInUp" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/cJhXZSs/pwc.png" alt="" className="w-52 block animatable bounceInLeft" />
                            </div>


                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/tJKqrMG/wells.png" alt="" className="xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>
                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/7nTLn45/microsoft.png" alt="" className="xl:w-44 w-32 block animatable fadeInUp" />
                            </div>

                            <div className="xl:mt-0 mt-6">
                                <img src="https://i.ibb.co/ZMhf4h5/capital.png" alt="" className="xl:w-44 w-32 block animatable bounceInRight" />
                            </div>
                        </div>
                    </div>
                    <div className="flex mx-auto justify-center -mt-4">
                        <a href="/projects">
                            <button className="text-center px-4 py-2 rounded  bg-red-900 text-white xl:mt-6 mt-12 fontfamily  xl:text-sm" type="submit">
                                View More <i className="fa fa-arrow-right pl-4"></i>
                            </button>
                        </a>
                    </div>
                </div>
            </section>



            <section className="bg-black">
                <section className="container mx-auto xl:px-12 xl:py-6 py-6 px-4 ">
                    <div className="xl:px-12 w-ful ">
                        <div className="flex flex-wrap xl:py-12 items-center">
                            <div className="xl:w-6/12 w-12/12  lg:w-6/12">
                                <h3 className="fontfamily xl:text-2xl text-3xl text-white font-semibold">
                                    Get In Touch
                                </h3>
                                <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                    <div className="flex flex-wrap">
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                            {nameError && firstName.length === 0 ?
                                                <label className="error">Enter Your First Name</label> : ""}
                                        </div>
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                            {nameeError && lastName.length === 0 ?
                                                <label className="error">Enter Your Last Name</label> : ""}
                                        </div>
                                    </div>

                                    <div className="px-2 xl:w-12/12 xl:mt-6 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                        {emailError && email.length === 0 ?
                                            <label className="error">Enter Your Email</label> : ""}
                                        {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                            <label className="error">Please Enter Valid Email</label> : ""}


                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <select
                                            id="country"
                                            name="country"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            className={`xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${countryError ? 'border-red-500' : ''}`}
                                        >
                                            <option value="">Select your country</option>
                                            {countryOptions.map((option) => (
                                                <option key={option.value} value={option.label}>{option.label}</option>
                                            ))}
                                        </select>
                                        {countryError && <p className="error">Please select your country.</p>}
                                    </div>


                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Phone Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                        {telError && tel.length === 0 ?
                                            <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                        {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                            <label className="error pt-2">Please Enter Valid Number</label> : ""}
                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                        {messageError && message.length === 0 ?
                                            <label className="error">Please Enter Your Message</label> : ""}
                                    </div>
                                    <div className="items-center text-center justify-center">
                                        <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12  lg:pl-12 xl:pl-12 xl:mt-0 mt-12">
                                <img src="https://i.ibb.co/g44TBCx/archywavebrouchure.jpg" alt="" className="rounded-lg"></img>
                            </div>
                        </div>

                    </div>
                </section>
            </section>

            <Footer />
        </>
    );
}
