/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import archywavelogo from "assets/img/archywavelogo.png";
import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg transparent borderb">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between py-3">
          <div className="xl:w-3/12">
            <Link
              to="/home"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              <img src="https://i.ibb.co/MVxr38y/LOGO7.png" alt="" className="xl:w-80" />
            </Link>
          </div>
          <div className="xl:w-8/12 px-6 ">
          <div className="flex flex-wrap items-center justify-center">
            <p className="text-black fontfamily xl:text-lg text-right px-4">
              Who We Are
            </p>
            <p className="text-black fontfamily xl:text-lg px-4">
              Packages
            </p>
            <p className="text-black fontfamily xl:text-lg px-4">
              What We Do
            </p>
            <p className="text-black fontfamily xl:text-lg px-4">
              Work
            </p>
            <p className="text-black fontfamily xl:text-lg px-4">
              Why
            </p>
            <p className="text-black fontfamily xl:text-lg px-4">
              Contact Us
            </p>
            
          </div>
            
          </div>
          <div className="xl:w-1/12 flex justify-end items-center">
            <div
              onClick={toggleSidebar}
              className="text-black fontfamily xl:text-xl font-light flex items-center cursor-pointer"
            >
              <img src="https://i.ibb.co/BT19ZpC/menu.png" alt="" className="w-8" />
            </div>
          </div>
        </div>
      </nav>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div onClick={toggleSidebar} className="close-btn">
          <img src="https://i.ibb.co/mN96B3V/closee.png" alt="" className="w-12" />
        </div>

        <div className="container mx-auto xl:px-32">
          <div className="w-full mt-24">
            <div className="flex flex-wrap">

              <div className="xl:w-7/12">

                <p className="text-white fontfamily xl:text-7xl font-light mt-16 uppercase">
                  Home
                </p>
                <a href="/projects">
                <p className="text-white fontfamily xl:text-7xl font-light mt-6 uppercase">
                  Projects
                </p>
                </a>
                <a href="/who-we-are">
                <p className="text-white fontfamily xl:text-7xl font-light mt-6 uppercase">
                  Studio
                </p>
                </a>
                <a href="/contact">
                <p className="text-white fontfamily xl:text-7xl font-light mt-6 uppercase">
                  Contact
                </p>
                </a>
              </div>

              <div className="xl:w-5/12 mt-16">
                <h1 className="text-white fontfamily xl:text-2xl font-light">
                  500 Terry Francine Street,<br></br>
                  San Francisco, CA 94158
                </h1>
                <p className="text-white fontfamily xl:text-2xl font-light mt-6">
                  info@mysite.com
                </p>
                <p className="text-white fontfamily xl:text-2xl font-light mt-2">
                  Tel: 123-456-7890
                </p>

                <p className="text-white fontfamily xl:text-xl font-light flex  items-center mt-12">
                  Facebook <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
                <p className="text-white fontfamily xl:text-xl font-light flex items-center mt-3">
                  LinkedIn <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
                <p className="text-white fontfamily xl:text-xl font-light flex items-center mt-3">
                  Instagram <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
                <p className="text-white fontfamily xl:text-xl font-light flex items-center mt-3">
                  X <img src="https://i.ibb.co/qjtq7P8/arrowws.png" alt="" className=" h-5 pl-4"></img>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar content goes here */}
      </div>
    </>
  );
}
