
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";
import countryList from 'react-select-country-list'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


// import Basics from "assets/img/Basics.png";
// import Standard from "assets/img/Standard.png";
// import premium from "assets/img/premium.png";
// import premiumplus from "assets/img/premiumplus.png";
// import premiumspacepro from "assets/img/premiumspacepro.png";
// import premiumspacepromax from "assets/img/premiumspacepromax.png";

import Basics from "assets/img/imagess/Basics.webp";
import Standard from "assets/img/imagess/Standard.webp";
import Premium from "assets/img/imagess/Premium.webp";
import Premiumplus from "assets/img/imagess/Premiumplus.webp";
import Premiumspacepromax from "assets/img/imagess/Premiumspacepromax.webp";
import Premiumspacepro from "assets/img/imagess/Premiumspacepro.webp";


import addon1 from "assets/img/imagess/addon1.webp";
import addon2 from "assets/img/imagess/addon2.webp";
import addon3 from "assets/img/imagess/addon3.webp";
import addon4 from "assets/img/imagess/addon4.webp";
import addon5 from "assets/img/imagess/addon5.webp";
import addon6 from "assets/img/imagess/addon6.webp";
import addon7 from "assets/img/imagess/addon7.webp";
import addon8 from "assets/img/imagess/addon8.webp";
import addon9 from "assets/img/imagess/addon9.webp";
import packagebanner from "assets/img/packagebanner.webp";


export default function Studio() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState('');

    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCountryError(country.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            toast.success("Form Submitted Successfully! Thank you for reaching out to us!", {
                position: toast.POSITION.CENTER_CENTER,
                autoClose: 3000, // Automatically close after 3 seconds
                hideProgressBar: false, // Show progress bar
                closeOnClick: false, // Don't close on click
                pauseOnHover: false, // Don't pause on hover
                draggable: false, // Disable dragging
                progress: undefined, // Use the default progress bar
                bodyClassName: "toast-success" // Custom class for toast body
            });

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');
            setSelectedPackage('')

            // Reset the form
            e.target.reset();
        }
    };


    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    const openModal = (imageSrc, packageName) => {
        setModalImage(imageSrc);
        setModalOpen(true);
        setSelectedPackage(packageName);
    };

    const closeModal = () => {
        setModalOpen(false);
    };


    return (
        <>
            <IndexNavbarr fixed />

            <section className="w-full" style={{
                backgroundImage: `url(${packagebanner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}>
                <div className="container mx-auto items-center xl:py-32" >
                    <div className='w-full xl:py-32 py-16'>
                        <div className='flex flex-wrap pt-12'>

                            <div className='xl:w-8/12  w-full mb-12'>
                                <h2 className='xl:block hidden xl:text-6xl text-3xl pl-3  uppercase text-left fontfamily1 font-semibold' style={{ color: '#DE0A26' }}>
                                    Packages for<br></br><p className='text-white mt-4'>
                                        Real Estate Marketing</p>
                                </h2>

                                <h2 className='xl:hidden block lg:px-10 px-0 lg:text-4xl text-3xl pl-3 uppercase text-left fontfamily1 font-semibold' style={{ color: '#DE0A26' }}>
                                    Packages for <span className='text-white'>
                                        Real Estate Marketing</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-black">
                <div className="container mx-auto xl:py-12">
                    <div className="text-white fontfamily1 xl:text-4xl text-2xl text-3xl pt-12 text-center xl:mt-12 ">
                        3D & VR PACKAGES
                    </div>
                    <div className="w-full xl:py-12 py-6 xl:px-12 px-4">
                        <div className="flex flex-wrap ">
                            <div className="xl:w-6/12 lg:w-6/12 w-12/12">
                                <div className="zoom2 text-center border border-red-800 ">
                                    <div className="justify-center text-center mx-auto web xl:mt-12 lg:mt-10 mt-6 ">
                                        <img src={Basics} className="xl:w-6/12"></img>
                                    </div>
                                    <h1 className="text-white fontfamily1 xl:text-4xl text-2xl  font-semibold pt-5 mb-6">
                                        Basics
                                    </h1>
                                    <hr className="border border-red-800"></hr>
                                    <div className="xl:py-12 py-6 text-white fontfamily1 xl:text-lg">
                                        <p className="font-semibold">
                                            Exterior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            2 close-up CGIs/renders
                                        </p>
                                        <p className="pt-6 font-semibold">
                                            Interior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            5 interior CGIs/renders
                                        </p>
                                    </div>
                                    <div className="justify-center mx-auto xl:w-4/12 w-6/12 mb-12 cursor-pointer">
                                        <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Light 3D")} className="bg-red-900 text-white fontfamily1 xl:text-lg py-2">
                                            Get a Quote
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12 w-12/12">
                                <div className="zoom2 text-center border border-red-800 ">
                                    <div className="justify-center text-center mx-auto web xl:mt-12 mt-6">
                                        <img src={Standard} className="xl:w-7/12"></img>
                                    </div>
                                    <h1 className="text-white fontfamily1 xl:text-4xl text-2xl font-semibold pt-4 mb-6">
                                        Standard
                                    </h1>
                                    <hr className="border border-red-800"></hr>
                                    <div className="xl:py-12 py-6 text-white fontfamily1 xl:text-lg">
                                        <p className="font-semibold">
                                            Exterior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            5 close-up CGIs/renders
                                        </p>
                                        <p className="pt-6 font-semibold">
                                            Interior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            10 interior CGIs/renders
                                        </p>

                                    </div>
                                    <div className="justify-center mx-auto xl:w-4/12 w-6/12 mb-12 cursor-pointer">
                                        <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Complex 3D")} className="bg-red-900 text-white fontfamily1 xl:text-lg py-2">
                                            Get a Quote
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12 w-12/12">
                                <div className="zoom2 text-center border border-red-800 hover:-mt-4 cursor-pointer">
                                    <div className="justify-center text-center mx-auto web xl:mt-12 lg:mt-1">
                                        <img src={Premium} className="xl:w-7/12"></img>
                                    </div>
                                    <h1 className="text-white fontfamily1 xl:text-4xl text-2xl font-semibold pt-8 mb-6">
                                        Premium
                                    </h1>
                                    <hr className="border border-red-800"></hr>
                                    <div className="xl:py-12 py-6 text-white fontfamily1 xl:text-lg">
                                        <p className="font-semibold">
                                            Exterior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            5 close-up CGIs/renders
                                        </p>
                                        <p className="pt-6 font-semibold">
                                            Interior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            10 interior CGIs/renders
                                        </p>

                                        <p className="pt-6 font-semibold">
                                            3D Animation Video
                                        </p>
                                        <p className="xl:text-base">
                                            30 – 60 sec fly-through
                                        </p>
                                    </div>
                                    <div className="justify-center mx-auto xl:w-4/12 w-6/12 mb-12 cursor-pointer">
                                        <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Complex + Video")} className="bg-red-900 text-white fontfamily1 xl:text-lg py-2">
                                            Get a Quote
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12 w-12/12">
                                <div className="zoom2 text-center border border-red-800 hover:-mt-4 cursor-pointer">
                                    <div className="justify-center text-center mx-auto web xl:mt-12">
                                        <img src={Premiumplus} className="xl:w-8/12"></img>
                                    </div>
                                    <h1 className="text-white fontfamily1 xl:text-4xl text-2xl font-semibold pt-6 mb-6">
                                        Premium Plus
                                    </h1>
                                    <hr className="border border-red-800"></hr>
                                    <div className="xl:py-12 py-6 text-white fontfamily1 xl:text-lg">
                                        <p className="font-semibold">
                                            Exterior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            5 close-up CGIs/renders
                                        </p>
                                        <p className="pt-6 font-semibold">
                                            Interior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            10 interior CGIs/renders
                                        </p>

                                        <p className="pt-6 font-semibold">
                                            1 VR Tour (web .html)
                                        </p>
                                        <p className="xl:text-base">
                                            * real window footage to be provided
                                            up to 15 hotspots
                                        </p>
                                    </div>
                                    <div className="justify-center mx-auto xl:w-4/12 w-6/12 mb-12 cursor-pointer">
                                        <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Complex + VR")} className="bg-red-900 text-white fontfamily1 xl:text-lg py-2">
                                            Get a Quote
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12 w-12/12">
                                <div className="zoom2 text-center border border-red-800 hover:-mt-4 cursor-pointer">
                                    <div className="justify-center text-center mx-auto xl:mt-12 lg:mt-4 web">
                                        <img src={Premiumspacepro} className="xl:w-7/12"></img>
                                    </div>
                                    <h1 className="text-white fontfamily1 xl:text-4xl text-2xl font-semibold pt-4 mb-6">
                                        Premium Space Pro
                                    </h1>
                                    <hr className="border border-red-800"></hr>
                                    <div className="xl:py-12 py-6 text-white fontfamily1 xl:text-lg">
                                        <p className="font-semibold">
                                            Exterior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            5 close-up CGIs/renders
                                        </p>
                                        <p className="pt-6 font-semibold">
                                            Interior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            10 interior CGIs/renders
                                        </p>

                                        <p className="pt-6 font-semibold">
                                            3D Animation Video
                                        </p>
                                        <p className="xl:text-base">
                                            30 – 60 sec fly-through
                                        </p>

                                        <p className="pt-6 font-semibold">
                                            Web Landing Page
                                        </p>
                                    </div>
                                    <div className="justify-center mx-auto xl:w-4/12 w-6/12 mb-6 cursor-pointer">
                                        <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Complex + Video + Website")} className="bg-red-900 text-white fontfamily1 xl:text-lg py-2">
                                            Get a Quote
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:w-6/12 lg:w-6/12 w-12/12">
                                <div className="zoom2 text-center border border-red-800 zoom2">
                                    <div className="justify-center text-center mx-auto xl:mt-12 web">
                                        <img src={Premiumspacepromax} className="xl:w-7/12"></img>
                                    </div>
                                    <h1 className="text-white fontfamily1 xl:text-4xl text-2xl font-semibold pt-5 mb-6">
                                        Premium Space Pro Max
                                    </h1>
                                    <hr className="border border-red-800"></hr>
                                    <div className="xl:py-12 py-6 text-white fontfamily1 xl:text-lg">
                                        <p className="font-semibold">
                                            Exterior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            5 close-up CGIs/renders
                                        </p>
                                        <p className="pt-6 font-semibold">
                                            Interior Rendering
                                        </p>
                                        <p className="xl:text-base">
                                            10 interior CGIs/renders
                                        </p>

                                        <p className="pt-6 font-semibold">
                                            1 VR Tour (web .html)
                                        </p>
                                        <p className="xl:text-base">
                                            * real window footage to be provided
                                        </p>

                                        <p className="pt-6 font-semibold">
                                            Web Landing Page
                                        </p>
                                    </div>
                                    <div className="justify-center mx-auto xl:w-4/12 w-6/12 mb-6 cursor-pointer">
                                        <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Complex + VR + Website")} className="bg-red-900 text-white fontfamily1 xl:text-lg py-2">
                                            Get a Quote
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








                <section className="container mx-auto xl:py-12 py-6">
                    <div className="w-full xl:px-12">
                        <h3 className="text-center text-white fontfamily1 xl:text-4xl text-2xl uppercase font-semibold mb-6">
                            Add-Ons
                        </h3>
                        <div className="flex flex-wrap ">
                            <div className="xl:w-4/12 lg:w-4/12 w-12/12  px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Interior Design")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon1})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 py-24 text-xl text-center font-semibold">
                                        Interior Design
                                    </h5>
                                </div>
                            </div>
                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0 mt-4 lg:mt-0 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Property Sales App")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon2})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 py-24 text-xl text-center font-semibold">
                                        Property Sales App
                                    </h5>
                                </div>
                            </div>
                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0 mt-4 lg:mt-0 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "VR App for Oculus Rift/HTC Vive")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon3})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 py-24 text-xl text-center font-semibold">
                                        VR App
                                    </h5>
                                </div>
                            </div>

                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0  py-4 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Interactive 3D Floorplan")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon4})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 py-24  text-xl text-center font-semibold">
                                        Interactive 3D Floorplan
                                    </h5>
                                </div>
                            </div>

                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0  py-4 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Web Landing Page")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon5})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 py-24 text-xl text-center font-semibold">
                                        Web Landing Page
                                    </h5>
                                </div>
                            </div>

                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0  py-4 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Real-time Rendering App")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon6})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 py-24 text-xl text-center font-semibold">
                                        Real-time Rendering App
                                    </h5>
                                </div>
                            </div>

                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0  px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Masterplan Fly-through Animation")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon7})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 text-xl py-24 text-center font-semibold">
                                        Masterplan Fly-through Animation
                                    </h5>
                                </div>
                            </div>

                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0 mt-4 lg:mt-0 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Interactive Masterplan")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon8})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 text-xl py-24 text-center font-semibold">
                                        Interactive Masterplan
                                    </h5>
                                </div>
                            </div>

                            <div className="xl:w-4/12 lg:w-4/12 w-12/12 xl:mt-0 mt-4 lg:mt-0 px-2">
                                <div onClick={() => openModal("https://i.ibb.co/ChVZVs4/974829dc-d0ad-4b7c-a570-805cc52a2332.jpg", "Scale Model")} className="xl:py-12 py-6 bg-black zoom3 cursor-pointer" style={{
                                    backgroundImage: `url(${addon9})`,
                                    backgroundPosition: 'bottom',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <h5 className="text-white fontfamily1 xl:text-xl xl:py-12 text-xl py-24 text-center font-semibold">
                                        Scale Model
                                    </h5>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </section>

            {modalOpen && (
                <div className="modal">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="modal-content">
                        <div className="container mx-auto">
                            <div className="xl:px-32">
                                <div className="bg-white rounded xl:py-12 py-6 xl:px-6">
                                    <h2 className="text-red-900 fontfamily1 font-bold text-center xl:text-3xl text-3xl">
                                        Your Packages
                                    </h2>
                                    <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                        <div className="flex flex-wrap">
                                            <div className="px-2 xl:w-12/12 w-12/12">
                                                <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                    placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                                {nameError && firstName.length === 0 ?
                                                    <label className="error">Enter Your First Name</label> : ""}
                                            </div>
                                            <div className="px-2 xl:w-12/12 w-12/12 mt-2">
                                                <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                    placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                                {nameeError && lastName.length === 0 ?
                                                    <label className="error">Enter Your Last Name</label> : ""}
                                            </div>
                                        </div>

                                        <div className="px-2 xl:w-12/12 mt-2 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                            {emailError && email.length === 0 ?
                                                <label className="error">Enter Your Email</label> : ""}
                                            {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                                <label className="error">Please Enter Valid Email</label> : ""}


                                        </div>

                                        <div className="px-2 xl:w-12/12 w-12/12 mt-1">
                                            <select
                                                id="country"
                                                name="country"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                                className={`xl:w-12/12 w-12/12 fontfamily px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${countryError ? 'border-red-500' : ''}`}
                                            >
                                                <option value="">Select your country</option>
                                                {countryOptions.map((option) => (
                                                    <option key={option.value} value={option.label}>{option.label}</option>
                                                ))}
                                            </select>
                                            {countryError && <p className="error">Please select your country.</p>}
                                        </div>


                                        <div className="px-2 xl:w-12/12 w-12/12 mt-2">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Phone Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                            {telError && tel.length === 0 ?
                                                <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                            {/* {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ? */}
                                            {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                                <label className="error pt-2">Please Enter Valid Number</label> : ""}
                                        </div>

                                        <div className="px-2 xl:w-12/12 w-12/12 mt-2">
                                            <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                            {messageError && message.length === 0 ?
                                                <label className="error">Please Enter Your Message</label> : ""}
                                        </div>
                                        <input type="hidden" name="package" id="package" value={selectedPackage} />
                                        <div className="items-center text-center justify-center">
                                            <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <ToastContainer position="top-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}




            <Footer />
        </>
    );
}
