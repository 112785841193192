import React, { useState, useEffect } from 'react';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button based on the scroll position
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top
  const scrollToTop = () => {
    console.log('Scroll to top button clicked'); // Debug log
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && 
        <button onClick={scrollToTop} style={styles.backToTop}>
          <img src='https://i.ibb.co/NC7LZYv/arrow-circle.png' alt='' className='w-24'></img>
        </button>
      }
    </div>
  );
};

const styles = {
  backToTop: {
    position: 'relsative',
    bottom: '0px',
    right: '30px',
    // padding: '10px 20px',
    // color: '#fff',
    // border: 'none',
    // borderRadius: '5px',
    cursor: 'pointer',
    zIndex: 1000,
  }
};

export default BackToTop;