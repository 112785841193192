/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";

export default function Home() {

    jQuery(function ($) {

        // Function which adds the 'animated' class to any '.animatable' in view
        var doAnimations = function () {

            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $animatables = $('.animatable');

            // Unbind scroll handler if we have no animatables
            if ($animatables.length == 0) {
                $(window).off('scroll', doAnimations);
            }

            // Check all animatables and animate them if necessary
            $animatables.each(function (i) {
                var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
                    $animatable.removeClass('animatable').addClass('animated');
                }
            });

        };

        // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
        $(window).trigger('scroll');

    });


    return (
        <>
            <IndexNavbarr fixed />
            <section className="w-full " style={{background: '#262525'
                
            }}>
                <div className="container mx-auto ">
                    <div className="flex justify-center">
                        <div className="xl:mt-32 mb-24">
                            <div className="justify-center text-center web">
                            <img src="https://i.ibb.co/855QW7b/LOGO2.png" alt="" className="w-52 xl:mt-32"/>
                            </div>
                            
                            <h1 className=" xl:text-6xl fontfamily text-red-900 xl:mt-6">
                                Coming<span className="text-white"> Soon...</span>
                            </h1>
                        </div>

                    </div>
                </div>
                <img src="https://i.ibb.co/p3vbqsZ/archy.jpg" alt="" className="w-full"/>

            </section>

            <Footer />
        </>
    );
}
