import React, { useState, useEffect } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";
import homebanner from "assets/img/homebanner.webp";
import interior1 from "assets/img/imagess/interior1.webp";
import interior2 from "assets/img/imagess/interior2.webp";
import interior3 from "assets/img/imagess/interior3.webp";
import interior4 from "assets/img/imagess/interior4.webp";
import interior5 from "assets/img/imagess/interior5.webp";
import interior6 from "assets/img/imagess/interior6.webp";
import interior7 from "assets/img/imagess/interior7.webp";
import interior8 from "assets/img/imagess/interior8.webp";


import exterior1 from "assets/img/imagess/exterior1.webp";
import exterior2 from "assets/img/imagess/exterior2.webp";
import exterior3 from "assets/img/imagess/exterior3.webp";
import exterior4 from "assets/img/imagess/exterior4.webp";
import exterior5 from "assets/img/imagess/exterior5.webp";
import exterior6 from "assets/img/imagess/exterior6.webp";
import exterior7 from "assets/img/imagess/exterior7.webp";
import exterior8 from "assets/img/imagess/exterior8.webp";
import exterior9 from "assets/img/imagess/exterior9.webp";
import exterior10 from "assets/img/imagess/exterior10.webp";
import exterior11 from "assets/img/imagess/exterior11.webp";
import exterior12 from "assets/img/imagess/exterior12.webp";
import exterior13 from "assets/img/imagess/exterior13.webp";
import exterior14 from "assets/img/imagess/exterior14.webp";
import exterior15 from "assets/img/imagess/exterior15.webp";
import exterior16 from "assets/img/imagess/exterior16.webp";


import tour1 from "assets/img/imagess/tour1.webp";
import tour2 from "assets/img/imagess/tour2.webp";
import tour3 from "assets/img/imagess/tour3.webp";


import VideoPlayer from "./VideoPlayer";


export default function Projects() {

    jQuery(function ($) {

        // Function which adds the 'animated' class to any '.animatable' in view
        var doAnimations = function () {

            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $animatables = $('.animatable');

            // Unbind scroll handler if we have no animatables
            if ($animatables.length == 0) {
                $(window).off('scroll', doAnimations);
            }

            // Check all animatables and animate them if necessary
            $animatables.each(function (i) {
                var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
                    $animatable.removeClass('animatable').addClass('animated');
                }
            });

        };

        // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
        $(window).trigger('scroll');

    });

    const [isPlaying, setIsPlaying] = useState(false);
    const [activeTab, setActiveTab] = useState("exterior");



    const handlePlay = () => {
        setIsPlaying(true);
        window.open('/villa/Villa.html', '_blank');
    };

    const handlePlayy = () => {
        setIsPlaying(true);
        window.open('/Shapoorji Palonji%20 3D VR%20 V08/index.html', '_blank');
    };

    const handlePlayyy = () => {
        setIsPlaying(true);
        window.open('/Tvs2bhk/Tvs2BHK.html', '_blank');
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);
    const [category, setCategory] = useState("");

    const interiorImages = [
        // "https://i.ibb.co/Dzj5CwH/Untitled-design-7.jpg",
        // "https://i.ibb.co/y8c3dp0/Untitled-design-10.jpg",
        // "https://i.ibb.co/gmRvSYY/Untitled-design.jpg",
        // "https://i.ibb.co/5M4MMYc/Untitled-design-3.jpg",
        // "https://i.ibb.co/f8YHxmD/Untitled-design-4.jpg",
        // "https://i.ibb.co/QNNJX5R/Untitled-design-5.jpg",
        // "https://i.ibb.co/fdCXR2K/Untitled-design-6.jpg",
        // "https://i.ibb.co/Rj3g8Sm/NSW.jpg",
        // "https://i.ibb.co/1mzFsnY/Untitled-design-16.jpg",
        // "https://i.ibb.co/jfNcNHB/Untitled-design-17.jpg",
        // "https://i.ibb.co/Wcgp3bg/Untitled-design-18.jpg",
        interior1,
        interior2,
        interior3,
        interior4,
        interior5,
        interior6,
        interior7,
        interior8,
    ];

    const exteriorImages = [
        // "https://i.ibb.co/z2z5xSJ/Untitled-design-21.jpg",
        // "https://i.ibb.co/LCcwYM5/Untitled-design-6.jpg",
        // "https://i.ibb.co/j4HsQsZ/Untitled-design-27.jpg",
        // "https://i.ibb.co/FBjHx6z/Untitled-design-32.jpg",
        // "https://i.ibb.co/S7gyg6f/Untitled-design-23.jpg",
        // "https://i.ibb.co/VY8SMJF/Untitled-design-14.jpg",
        // "https://i.ibb.co/dK7pQ5B/Untitled-design.jpg",
        // "https://i.ibb.co/xS8DCZq/Untitled-design-1.jpg",
        // "https://i.ibb.co/tJsRJyp/Untitled-design-2.jpg",
        // "https://i.ibb.co/7pwng76/Untitled-design-3.jpg",
        // "https://i.ibb.co/Xbc1FSj/NSW.jpg",
        // "https://i.ibb.co/TKjNV4P/Untitled-design-5.jpg",
        // "https://i.ibb.co/rv7kyZs/Untitled-design-7.jpg",
        // "https://i.ibb.co/jL8dgfF/Untitled-design-8.jpg",
        // "https://i.ibb.co/dbjCMkR/Untitled-design-12.jpg",
        // "https://i.ibb.co/VDC3fjs/Untitled-design-19.jpg",
        // "https://i.ibb.co/WcJsgxW/Untitled-design-20.jpg",
        // "https://i.ibb.co/Km1hymH/Untitled-design-15.jpg",
        // "https://i.ibb.co/0YQDDVg/Untitled-design-29.jpg",
        // "https://i.ibb.co/tHZv1Vv/Untitled-design-28.jpg",
        exterior1,
        exterior2,
        exterior3,
        exterior4,
        exterior5,
        exterior6,
        exterior7,
        exterior8,
        exterior9,
        exterior10,
        exterior11,
        exterior12,
        exterior13,
        exterior14,
        exterior15,
        exterior16,

    ];

    const openModal = (imageSrc, index, category) => {
        setModalImage(imageSrc);
        setCurrentIndex(index);
        setCurrentImages(category === "interior" ? interiorImages : exteriorImages);
        setCategory(category);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
        setModalImage(currentImages[(currentIndex + 1) % currentImages.length]);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? currentImages.length - 1 : prevIndex - 1
        );
        setModalImage(
            currentImages[currentIndex === 0 ? currentImages.length - 1 : currentIndex - 1]
        );
    };

    const renderContent = () => {
        switch (activeTab) {
            case "exterior":
                return (
                    <section className="container mx-auto xl:mt-12 mt-12 xl:px-0 px-4">
                        <h1 className="font-semibold fontfamily uppercase text-black text-left xl:text-3xl text-2xl py-6">
                            Exterior
                        </h1>
                        <div className="flex flex-wrap xl:gap-4">
                            {exteriorImages.slice(0, exteriorImages.length - 2).map((image, index) => (
                                <div
                                    key={index}
                                    className="xl:w-12/12 py-1 h-auto px-1 image-container"
                                    onClick={() => openModal(image, index, "exterior")}
                                >
                                    <img src={image} alt="" className="image" />
                                </div>
                            ))}
                            <div className="xl:w-12/12 flex flex-wrap py-1 h-auto px-1">
                                {exteriorImages.slice(-2).map((image, index) => (
                                    <div
                                        key={index}
                                        className="xl:w-6/12 py-1 h-auto px-1 image-container"
                                        onClick={() => openModal(image, exteriorImages.length - 2 + index, "exterior")}
                                    >
                                        <img src={image} alt="" className="image" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                );
            case "interior":
                return (
                    <section className="container mx-auto xl:mt-12 mt-12 xl:px-0 px-4">
                        <h1 className="font-semibold uppercase fontfamily text-black text-left xl:text-3xl text-2xl  py-6">
                            Interior
                        </h1>
                        <div className="flex flex-wrap xl:gap-4">
                            {interiorImages.map((image, index) => (
                                <div
                                    key={index}
                                    className="xl:w-12/12 py-1 h-auto px-1 image-container "
                                    onClick={() => openModal(image, index, "interior")}
                                >
                                    <img src={image} alt="" className="image" />
                                </div>
                            ))}
                        </div>
                    </section>
                );
            case "360tour":
                return (
                    <section className="container mx-auto xl:mt-12 xl:px-0 px-4">
                        <h1 className="font-semibold uppercase fontfamily text-black text-left xl:text-3xl text-2xl py-6">
                            360 Tour
                        </h1>
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {!isPlaying && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                        zIndex: '1',
                                    }}
                                    onClick={handlePlay}
                                >
                                    <img src="https://i.ibb.co/d2QB4Cm/youtube.png" alt="Play" style={{ width: '80px', height: '80px' }} />
                                </div>
                            )}
                            <div
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    backgroundImage: `url(${tour1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    zIndex: '-1',
                                }}
                            />
                        </div>


                        <div className="mt-12" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {!isPlaying && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                        zIndex: '1',
                                    }}
                                    onClick={handlePlayy}
                                >
                                    <img src="https://i.ibb.co/d2QB4Cm/youtube.png" alt="Play" style={{ width: '80px', height: '80px' }} />
                                </div>
                            )}
                            <div
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    backgroundImage: `url(${tour2})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    zIndex: '-1',
                                }}
                            />
                        </div>


                        <div className="mt-12" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {!isPlaying && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                        zIndex: '1',
                                    }}
                                    onClick={handlePlayyy}
                                >
                                    <img src="https://i.ibb.co/d2QB4Cm/youtube.png" alt="Play" style={{ width: '80px', height: '80px' }} />
                                </div>
                            )}
                            <div
                                style={{
                                    width: '100%',
                                    height: '600px',
                                    backgroundImage: `url(${tour3})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    zIndex: '-1',
                                }}
                            />
                        </div>
                    </section>
                );
            case "3dflythrough":
                return (
                    <section className="container mx-auto xl:mt-12 xl:px-0 px-4">
                        <h1 className="font-semibold uppercase fontfamily text-black text-left xl:text-3xl text-2xl py-6">
                            3D Flythrough
                        </h1>
                        <div className="xl:w-12/12">
                            {/* <iframe className="w-12/12 xl:w-12/12 lg:w-12/12 md:w-12/12" height="500px"
                                src="https://www.youtube.com/embed/drQtiL2IvIA?si=1-3qCJXzWJz8WuyO">
                            </iframe> */}
                            <VideoPlayer sourceLink="https://fast.wistia.net/embed/iframe/e4pdodkl93?seo=true&videoFoam=true" />
                        </div>
                        <div className="xl:w-12/12 xl:mt-6 mt-6">
                            {/* <iframe className="w-12/12 xl:w-12/12 lg:w-12/12 md:w-12/12" height="500px"
                                src="https://www.youtube.com/embed/iJrxv30BFgE?si=hlTgOT01es-puqFR">
                            </iframe> */}
                            <VideoPlayer sourceLink="https://fast.wistia.net/embed/iframe/0fvn3e8v9o?seo=true&videoFoam=true" />
                        </div>
                        <div className="xl:w-12/12 xl:mt-6 mt-6">
                            {/* <iframe className="w-12/12 xl:w-12/12 lg:w-12/12 md:w-12/12" height="500px"
                                src="https://www.youtube.com/embed/1PqewoipdLo?si=VzIkFBVhVOWLC6nb">
                            </iframe> */}
                            <VideoPlayer sourceLink="https://fast.wistia.net/embed/iframe/plvnbq7pvq?seo=true&videoFoam=true" />
                        </div>
                        <div className="xl:w-12/12 xl:mt-6 mt-6 xl:mb-32" >
                            {/* <iframe className="w-12/12 xl:w-12/12 lg:w-12/12 md:w-12/12" height="500px"
                                src="https://www.youtube.com/embed/Ztyfmztgv3g?si=xXtsF4cuuT4uTQQd">
                            </iframe> */}
                            <VideoPlayer sourceLink="https://fast.wistia.net/embed/iframe/cbwhicgg86?seo=true&videoFoam=true" />
                        </div>
                    </section>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <IndexNavbarr fixed />

            <section className="w-full" style={{
                backgroundImage: `url(${homebanner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}>
                <div className="container mx-auto items-center xl:py-32">
                    <div className='w-full xl:py-32 py-14'>
                        <div className='flex flex-wrap xl:py-12 py-24'>
                            <div className='xl:w-8/12  mb-12'>
                                <h2 className='xl:mt-0 mt-12 text-4xl xl:text-6xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    Our <span className='text-white'>Works</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mx-auto xl:mt-32 mt-12 xl:px-0 px-4">
                <div className="flex flex-wrap justify-center bg-gray-100 py-2">
                    <div className={`cursor-pointer uppercase zoom4 xl:text-2xl text-black py-2 xl:px-12 px-6 fontfamily ${activeTab === "exterior" ? "border-b-4 border-red-800 text-red-900 fontfamily font-normal font-light" : "bg-gray-200 fontfamily hover:border-b-4 border-red-800 font-semibold"}`} onClick={() => setActiveTab("exterior")}>Exterior</div>
                    <div className={`cursor-pointer uppercase zoom4 xl:text-2xl text-black py-2 xl:px-12 px-6 fontfamily ${activeTab === "interior" ? "border-b-4 border-red-800 text-red-900 fontfamily font-normal font-light" : "bg-gray-200 hover:border-b-4 border-red-800 font-semibold"}`} onClick={() => setActiveTab("interior")}>Interior</div>
                    <div className={`cursor-pointer uppercase zoom4 xl:text-2xl text-black py-2 xl:px-12  px-6  fontfamily xl:mt-0 lg:mt-0 md:mt-0 mt-6 ${activeTab === "360tour" ? "border-b-4 border-red-800 text-red-900 fontfamily font-normal font-light" : "bg-gray-200 hover:border-b-4 border-red-800 font-semibold"}`} onClick={() => setActiveTab("360tour")}>360 Tour</div>
                    <div className={`cursor-pointer uppercase zoom4 xl:text-2xl text-black py-2 xl:px-12  px-6 fontfamily xl:mt-0 lg:mt-0 md:mt-0 mt-6 ${activeTab === "3dflythrough" ? "border-b-4 border-red-800 text-red-900 fontfamily font-normal font-light" : "bg-gray-200 hover:border-b-4 border-red-800 font-semibold"}`} onClick={() => setActiveTab("3dflythrough")}>3D Flythrough</div>
                </div>
            </section>

            {renderContent()}

            {modalOpen && (
                <div className="modal" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>
                        &times;
                    </span>
                    <div className="modal-content">
                        <div className="modal-image-container">
                            <span
                                className="modal-prev"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    prevImage();
                                }}
                            >
                                &#10094;
                            </span>
                            <img
                                src={modalImage}
                                alt="Zoomed Image"
                                className="modal-image"
                            />
                            <span
                                className="modal-next"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    nextImage();
                                }}
                            >
                                &#10095;
                            </span>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </>
    );
}
