import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import bannerr1 from "assets/img/imagess/bannerr1.webp";
import bannerr2 from "assets/img/imagess/bannerr2.webp";
import bannerr3 from "assets/img/imagess/bannerr3.webp";
import bannerr4 from "assets/img/imagess/bannerr4.webp";
import bannerr5 from "assets/img/imagess/bannerr5.webp";
import bannerr6 from "assets/img/imagess/bannerr6.webp";
import bannerr7 from "assets/img/imagess/bannerr7.webp";
import bannerarchywave8 from "assets/img/bannerarchywave8.webp";
import bannerarchywave9 from "assets/img/bannerarchywave9.webp";
import rightarroww from "assets/img/rightarroww.png";

// Keyframe animations for fade in and out with zoom
const fadeInOut = keyframes`
  0% { opacity: 0; transform: scale(1); }
  12.5% { opacity: 1; transform: scale(1.1); }
  37.5% { opacity: 1; transform: scale(1.1); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 1; transform: scale(1); }
`;

// Keyframe animations for slide in from the right
const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Styled components
const SlideshowContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const SlidesWrapper = styled.ul`
  list-style-type: none;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const Slide = styled.li`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  color: #000 !important;
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */
  animation: ${({ isActive }) => isActive && fadeInOut} 16s linear infinite;
  z-index: ${({ isActive }) => (isActive ? 1 : 0)}; /* Ensure active slide is above others */
`;

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  justify-content: center;
  text-align: left; /* Align text to the left */
  position: absolute;
  z-index: 2; /* Ensure content is above background */
  color: white; /* Ensuring the text is visible */
  padding: 2rem; /* Add padding to keep content away from the edge */
  width: 100%;
  max-width: 1200px; /* Set a max width for the content */
  margin-left: 10%; /* Adjust margin to match logo alignment */
`;

const SlideHeading = styled.h2`
  animation: ${slideInFromRight} 1s ease-out forwards;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  ${({ direction }) => (direction === 'left' ? 'left: 10px;' : 'right: 10px;')}
  width: 40px;
  height: 40px;
  // background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  transform: translateY(-50%);
`;

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      src: bannerr1,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-3xl md:text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            3D <span className='text-white'>Visualisation</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerr2,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-3xl md:text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            3D <span className='text-white'>Visualisation</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerr3,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-3xl md:text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            VIRTUAL <span className='text-white'>Reality Tour</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerr4,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-3xl md:text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            Real-Time  <span className='text-white'>Rendering</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src:bannerr5,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-3xl md:text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            Fly-Through <span className='text-white'>Animation</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerr6,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-3xl md:text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            Landing <span className='text-white'>Page</span>
          </SlideHeading>
          
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerr7,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            Real Estate <span className='text-white'><br></br>Sales App</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerarchywave8,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            Fly-Through <span className='text-white'><br></br>Animation</span>
          </SlideHeading>
            <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    },
    {
      src: bannerarchywave9,
      content: (
        <div className='container mx-auto text-left w-full xl:mt-6'>
          <SlideHeading className='xl:text-6xl text-4xl fontfamily font-semibold uppercase' style={{ color: '#DE0A26' }}>
            Scale <span className='text-white'>Model</span>
          </SlideHeading>
         <div className=''>
            <a href='/home' className='bg-red-900 mt-6 xl:w-2/12 lg:w-3/12 md:w-4/12 w-10/12 text-white fontfamily text-center justify-center xl:text-lg text-base py-2 rounded uppercase flex items-center'>
              Explore <img src={rightarroww} className='w-12 pl-4' alt='Explore' />
              </a>
            </div>
        </div>
      )
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 8000); // Change slide every 8 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <>
      <IndexNavbarr fixed />
      <SlideshowContainer>
        <SlidesWrapper>
          {slides.map((slide, index) => (
            <Slide key={index} src={slide.src} isActive={index === currentSlide}>
              <SlideContent className='contentwidth'>
                {slide.content}
              </SlideContent>
            </Slide>
          ))}
        </SlidesWrapper>
        <Arrow direction="left" onClick={goToPreviousSlide}><i className='fa fa-chevron-left text-2xl'></i></Arrow>
        <Arrow direction="right" onClick={goToNextSlide}> <i className='fa fa-chevron-right text-2xl'></i></Arrow>
      </SlideshowContainer>
    </>
  );
};

export default Slider;
  