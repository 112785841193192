/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import $ from "jquery";
import countryList from 'react-select-country-list'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import brouchure from "assets/img/brouchure.png";
import aboutbanner from "assets/img/aboutbanner.webp";
import whoweare from "assets/img/whoweare.webp";
import ourmission from "assets/img/ourmission.webp";



import logo1 from "assets/img/imagess/logo1.webp";
import logo2 from "assets/img/imagess/logo2.webp";
import logo3 from "assets/img/imagess/logo3.webp";
import logo4 from "assets/img/imagess/logo4.webp";
import logo5 from "assets/img/imagess/logo5.webp";
import logo6 from "assets/img/imagess/logo6.webp";
import logo7 from "assets/img/imagess/logo7.webp";


import { isValidPhoneNumber } from 'libphonenumber-js';

export default function About() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);


    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        setCountryError(country.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            toast.success("Form Submitted Successfully! Thank you for reaching out to us!", {
                position: toast.POSITION.CENTER_CENTER,
                autoClose: 3000, // Automatically close after 3 seconds
                hideProgressBar: false, // Show progress bar
                closeOnClick: false, // Don't close on click
                pauseOnHover: false, // Don't pause on hover
                draggable: false, // Disable dragging
                progress: undefined, // Use the default progress bar
                bodyClassName: "toast-success" // Custom class for toast body
            });

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');

            // Reset the form
            e.target.reset();
        }
    };



    jQuery(function ($) {

        // Function which adds the 'animated' class to any '.animatable' in view
        var doAnimations = function () {

            // Calc current offset and get all animatables
            var offset = $(window).scrollTop() + $(window).height(),
                $animatables = $('.animatable');

            // Unbind scroll handler if we have no animatables
            if ($animatables.length == 0) {
                $(window).off('scroll', doAnimations);
            }

            // Check all animatables and animate them if necessary
            $animatables.each(function (i) {
                var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
                    $animatable.removeClass('animatable').addClass('animated');
                }
            });

        };

        // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
        $(window).trigger('scroll');

    });

    return (
        <>
            <IndexNavbarr fixed />
            <section className="" style={{ backgroundImage: `url(${aboutbanner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                <div className="container mx-auto items-center xl:px-0 lg:px-10 xl:py-32 py-24" >
                    <div className='w-full xl:py-32'>
                        <div className='flex flex-wrap xl:mb-12'>
                            <div className='xl:w-8/12 mt-12 mb-6'>
                                <h2 className=' xl:text-6xl lg:text-3xl text-5xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    About <span className='text-white'>Us</span>
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="container mx-auto mt-6">
                <div className="flex flex-wrap w-full">
                    <div className="xl:w-6/12">
                        <h3 className="text-black xl:text-7xl fontfamily font-medium   uppercase">
                            FRANKLIN<br></br>
                            GARDENS
                        </h3>
                        <p className="text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className=" align-left w-20 h-32" alt="" />
                    </div>
                </div>
            </section>


            <section className="container mx-auto mt-6">
                <div className="flex flex-wrap w-full items-end">
                    <div className="xl:w-6/12">
                        <h3 className="slide-right text-black xl:text-7xl fontfamily font-medium   uppercase">
                            RESIDENTIAL <br></br>GREEN<br></br>
                            BUILDING
                        </h3>
                        <p className="slide-right text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className=" align-left w-20 h-32" alt="" />
                    </div>
                    <div className="xl:w-6/12">
                        <img src="https://i.ibb.co/JtCryTq/archywave2.jpg" alt="" className="slide-left" />
                    </div>
                </div>
            </section>

            <section className="container mx-auto mt-32">
                <div className="w-full">
                    <div className="xl:w-12/12">
                        <img src="https://i.ibb.co/g9sVScZ/archywave1.png" alt="" className=" w-full h-80" />
                    </div>
                    <div className="xl:w-12/12 mt-8">
                        <h3 className="text-black xl:text-7xl fontfamily font-medium   uppercase">
                            GREEN-WATER<br></br>
                            OPEN AIR THEATRE
                        </h3>
                        <p className="text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className=" align-left w-20 h-32" alt="" />
                    </div>
                </div>
            </section>

            <section className="container mx-auto mt-2 xl:mb-32">
                <div className="flex flex-wrap w-full items-end">
                    <div className="xl:w-6/12">
                        <h3 className="slide-right text-black xl:text-7xl fontfamily font-medium   uppercase">
                            NY ART <br></br>MUSEUM<br></br>
                            BRIDGE
                        </h3>
                        <p className="slide-right text-black xl:text-xl mt-2 fontfamily font-light underline pl-4">
                            View More
                        </p>
                        <img src="https://i.ibb.co/XWPDg00/arrow.png" className="align-left w-20 h-32" alt="" />
                    </div>
                    <div className="xl:w-6/12">
                        <img src="https://i.ibb.co/c20tvf9/archywave4.jpg" alt="" className="slide-left h-80" />
                    </div>
                </div>
            </section> */}


            <section className="container mx-auto xl:mt-12 xl:px-12 px-4 mt-6">
                <div className="w-full ">
                    <div className="flex flex-wrap items-center">
                        <div className="xl:w-6/12 lg:w-6/12 ">
                            <h1 className=" block animatable fadeInUp fontfamily xl:text-4xl text-black text-2xl font-semibold mt-2 text-black" >
                                Who We Are
                            </h1>
                            <p style={{ color: '#DE0A26' }} className=" block animatable fadeInDown font-semibold fontfamily text-justify  lines xl:text-sm text-sm">
                                Revolutionizing Real Estate with 3D Visualization and IT Solutions
                            </p>

                            <p className="block animatable bounceInLeft fontfamily text-justify mt-4 lines xl:text-base text-sm">
                                Since 2024, Archywave has been at the forefront of transforming the real estate
                                sector through innovative 3D visualization and state-of-the-art IT solutions.
                                Our expert team consistently delivers exceptional results, utilizing the latest
                                technologies to surpass client expectations.
                            </p>

                            <p className="block animatable bounceInLeft fontfamily text-justify mt-4 lines xl:text-base text-sm">
                                We specialize in managed end-to-end architecture visualization solutions,
                                providing seamless integration from concept to completion. This comprehensive
                                approach ensures unparalleled accuracy, efficiency, and quality in every project
                                we undertake.
                            </p>
                        </div>
                        <div className="xl:w-6/12 lg:w-6/12 lg:pl-12 xl:pl-12 block animatable bounceInRight xl:mt-0 mt-6">
                            <img src={whoweare} alt="" className="xl:h-80 rounded-lg"></img>
                        </div>
                    </div>
                </div>
            </section>



            <section className="container mx-auto xl:mt-12 xl:px-12 px-4 mt-6 ">
                <div className="w-full ">
                    <div className="flex flex-wrap items-center xl:flex-col-reverse flex-col-reverse lg:flex-col-reverse">
                        <div className="xl:w-6/12 lg:w-6/12  xl:pl-12 block animatable bounceInRight xl:mt-0 mt-6">
                            <img src={ourmission} alt="" className="rounded-lg"></img>
                        </div>
                        <div className="xl:w-6/12 lg:w-6/12 xl:pl-12  pl-0 lg:pl-12 ">
                            <h1 className=" block animatable fadeInUp fontfamily xl:text-4xl text-black text-2xl font-semibold mt-2 text-black" >
                                Our Mission
                            </h1>
                            <p style={{ color: '#DE0A26' }} className=" block animatable fadeInDown font-semibold fontfamily text-justify  lines xl:text-sm text-sm">
                                Our Goal is to Provide the Best Quality
                            </p>

                            <p className="block animatable bounceInLeft fontfamily text-justify mt-4 lines xl:text-base text-sm">
                                At Archywave, our mission is to advance the IT industry by developing
                                innovative software for the real estate sector. We are dedicated to delivering
                                top-quality results, leveraging the latest technologies, and building strong
                                client relationships. Our goal is to create transformative solutions that enhance
                                the real estate experience and drive industry growth.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="bg-slate-100 xl:mt-12 xl:px-12">
                <div className="container mx-auto xl:py-12 xl:px-12 ">
                    <div className="w-full ">
                        <div className="flex flex-wrap items-center">
                            <div className="xl:w-6/12 block animatable bounceInLeft">
                                <img src="https://i.ibb.co/Fw71NSZ/archywaveabout1.webp" alt="" className="rounded-lg"></img>
                            </div>
                            <div className="xl:w-6/12  xl:pl-12">
                                <h1 style={{ color: '#DE0A26' }} className="uppercase block animatable fadeInUp fontfamily xl:text-base font-semibold mt-2 text-black" >
                                    Our Mission
                                </h1>
                                <p className="text-black block animatable fadeInDown font-semibold fontfamily text-justify mt-3 lines xl:text-4xl">
                                    Our Goal is to Provide the Best Quality
                                </p>

                                <p className="block animatable bounceInRight text-black fontfamily text-justify mt-4 lines xl:text-sm">
                                    At Archywave, our mission is to advance the IT industry by developing
                                    innovative software for the real estate sector. We are dedicated to delivering
                                    top-quality results, leveraging the latest technologies, and building strong
                                    client relationships. Our goal is to create transformative solutions that enhance
                                    the real estate experience and drive industry growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* <section className="container mx-auto xl:mt-12 xl:px-12">
                <div className="w-full">
                    <h2 className="fontfamily xl:text-4xl font-semibold  text-center text-black">
                        Clients
                    </h2>
                    <p className="mt-4 fontfamily text-black xl:text-base text-center">
                        Our objective is to be a long term and trusted partner to our clients
                    </p>
                    <div className="flex flex-wrap justify-between items-center  xl:px-32">
                        <div className="">
                            <img src="https://i.ibb.co/1ZDkQBJ/New-Product-Mockup-Now-Available-Marketing-Promotion-Instagram-Post-1-removebg-preview.png" alt="" className="w-32" />
                        </div>
                        <div className="">
                            <img src="https://i.ibb.co/tK7gg45/New-Product-Mockup-Now-Available-Marketing-Promotion-Instagram-Post-2-removebg-preview.png" alt="" className="w-32" />
                        </div>
                        <div className="">
                            <img src="https://i.ibb.co/3Wwf2Hx/New-Product-Mockup-Now-Available-Marketing-Promotion-Instagram-Post-3-removebg-preview.png" alt="" className="w-32 pt-2" />
                        </div>
                        <div className="">
                            <img src="https://i.ibb.co/4fckW48/New-Product-Mockup-Now-Available-Marketing-Promotion-Instagram-Post-4-removebg-preview.png" alt="" className="w-32" />
                        </div>
                        <div className="">
                            <img src="https://i.ibb.co/F5vzzMs/New-Product-Mockup-Now-Available-Marketing-Promotion-Instagram-Post-5-removebg-preview.png" alt="" className="w-32" />
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="bg-gray-100 xl:mt-12 mt-12">
                <div className="container mx-auto xl:py-12 py-6">
                    <div className="w-full">
                        <h2 className="fontfamily xl:text-4xl text-3xl font-semibold  text-center text-black ">
                            Clients
                        </h2>
                        <div className="flex flex-wrap justify-center items-center  xl:py-12">
                            <div className="xl:pt-0 pt-6">
                                <img src={logo1} alt="" className="xl:w-44  w-32 block animatable bounceInLeft" />
                            </div>
                            <div className="xl:pt-0 pt-6">
                                <img src={logo2} alt="" className="xl:w-44 w-32 block animatable fadeInDown" />
                            </div>
                            <div className="xl:pt-0 pt-6">
                                <img src={logo3} alt="" className="xl:w-44 w-32 block animatable fadeInUp" />
                            </div>
                            <div className="xl:pt-0 pt-6">
                                <img src={logo4} alt="" className="xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>


                            <div className="xl:pt-0 pt-6">
                                <img src={logo5} alt="" className="xl:w-44 w-32 block animatable bounceInLeft" />
                            </div>
                            <div className="xl:pt-0 pt-6">
                                <img src={logo6} alt="" className="xl:w-44 w-32 block animatable fadeInUp" />
                            </div>

                            <div className="xl:pt-0 pt-6">
                                <img src={logo7} alt="" className="xl:w-44 w-32 block animatable bounceInRight" />
                            </div>
                        </div>
                    </div>
                    <div className="flex mx-auto justify-center -mt-4">
                        <a href="/projects">
                            <button className="text-center px-4 py-2  xl:mt-6 rounded  bg-red-900 text-white mt-12 fontfamily  xl:text-sm" type="submit">
                                View More <i className="fa fa-arrow-right pl-4"></i>
                            </button>
                        </a>
                    </div>
                </div>
            </section>



            <section className="bg-black">
                <section className="container mx-auto xl:px-12 xl:py-6 py-6 px-4 ">
                    <div className="xl:px-12 w-ful ">
                        <div className="flex flex-wrap xl:py-12 items-center">
                            <div className="xl:w-6/12 w-12/12 lg:w-6/12 ">
                                <h3 className="fontfamily xl:text-2xl text-3xl text-white font-semibold">
                                    Get In Touch
                                </h3>
                                <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                    <div className="flex flex-wrap">
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                            {nameError && firstName.length === 0 ?
                                                <label className="error">Enter Your First Name</label> : ""}
                                        </div>
                                        <div className="px-2 xl:w-6/12 w-12/12">
                                            <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                                placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                            {nameeError && lastName.length === 0 ?
                                                <label className="error">Enter Your Last Name</label> : ""}
                                        </div>
                                    </div>

                                    <div className="px-2 xl:w-12/12 xl:mt-6 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                        {emailError && email.length === 0 ?
                                            <label className="error">Enter Your Email</label> : ""}
                                        {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                            <label className="error">Please Enter Valid Email</label> : ""}


                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6 mt-2">
                                        <select
                                            id="country"
                                            name="country"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            className={`fontfamily shadow appearance-none border h-12 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${countryError ? 'border-red-500' : ''}`}
                                        >
                                            <option value="">Select your country</option>
                                            {countryOptions.map((option) => (
                                                <option key={option.value} value={option.label}>{option.label}</option>
                                            ))}
                                        </select>
                                        {countryError && <p className="error">Please select your country.</p>}
                                    </div>


                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <input
                                            type="tel"
                                            value={tel}
                                            onChange={(e) => setTel(e.target.value)}
                                            placeholder="Phone Number"
                                            name="tel"
                                            className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                        />
                                        {telError && <label className="error pt-2">Phone Number is required</label>}
                                        {telregexError && <label className="error pt-2">Please enter a valid phone number</label>}
                                    </div>

                                    <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                        <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                        {messageError && message.length === 0 ?
                                            <label className="error">Please Enter Your Message</label> : ""}
                                    </div>
                                    <div className="items-center text-center justify-center">
                                        <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                                <ToastContainer position="top-right" />
                            </div>



                            <div className="xl:w-6/12 lg:w-6/12 lg:pl-12 xl:pl-12 xl:mt-0 mt-12">
                                <img src={brouchure} alt="" className="rounded-lg"></img>
                            </div>
                        </div>

                    </div>






                </section >
            </section >


            <Footer />
        </>
    );
}
