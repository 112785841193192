/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import countryList from 'react-select-country-list'
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import career from "assets/img/carrer.png";

export default function Contact() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');
    const [department, setDepartment] = useState('');
    const [visa, setVisa] = useState('');
    const [resume, setResume] = useState(null);
    const [portfolio, setPortfolio] = useState(null);

    const [nameError, setNameError] = useState(false);
    const [nameeError, setNameeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailregexError, setEmailRegexError] = useState(false);
    const [telError, setTelError] = useState(false);
    const [telregexError, setTelRegexError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [departmentError, setDepartmentError] = useState(false);
    const [visaError, setVisaError] = useState(false);

    // const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError(firstName.length === 0);
        setNameeError(lastName.length === 0);
        setEmailError(email.length === 0);
        setTelError(tel.length === 0);
        setMessageError(message.length === 0);
        // setVisaError(visa.length === 0);

        if (email.length > 0 && !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setEmailRegexError(true);
        } else {
            setEmailRegexError(false);
        }

        if (department.length === 0) {
            setDepartmentError(true);
        } else {
            setDepartmentError(false);
        }

        if (country.length === 0) {
            setCountryError(true);
        } else {
            setCountryError(false);
        }

        if (visa.length === 0) {
            setVisaError(true);
        } else {
            setVisaError(false);
        }

        if (tel.length > 0 && !(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) {
            setTelRegexError(true);
        } else {
            setTelRegexError(false);
        }

        if (firstName && lastName && email && tel && message && department && visa && country && !emailregexError && !telregexError) {
            console.log("First Name: ", firstName);
            console.log("Last Name: ", lastName);
            console.log("Email: ", email);
            console.log("Tel: ", tel);
            console.log("Message: ", message);
            console.log("Country: ", country);
            console.log("Department: ", department);
            console.log("Visa: ", visa);

            const form = new FormData();
            form.append("name", firstName + ' ' + lastName);
            form.append("email", email);
            form.append("phone", tel);
            form.append("department", department);
            form.append("country", country);
            form.append("visa_type", visa);
            form.append("resume", resume);
            form.append("portfolio", portfolio);

            emailjs.sendForm('service_pkgyfs7', 'template_9ukwsqi', e.target, '076KSZ_e1qDX1KE1V')
            const url = window.location.protocol + '://' + window.location.hostname + ':' + window.location.port == "3000" ? '3000' : '' + '/email/send_email.php';

            axios.post(url, form)
                .then(response => {
                    alert(response.data);
                })
                .catch(error => {
                    console.error('There was an error sending the email!', error);
                });

            setFirstName('');
            setLastName('');
            setEmail('');
            setTel('');
            setMessage('');
            setCountry('');
            setDepartment('');
            setVisa('');
            setPortfolio(null);
            setResume(null);

            // Reset the form
            e.target.reset();
        }
    };


    return (
        <>
            <IndexNavbarr fixed />

            <section className="w-full" style={{
                backgroundImage: `url(${career})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                backgroundSize: 'cover', backgroundColor: '#000'
            }}>
                <div className="container mx-auto items-center xl:py-32" >
                    <div className='w-full xl:py-12 py-24'>
                        <div className='flex flex-wrap'>

                            <div className='xl:w-6/12  xl:px-0 px-0 lg:px-10 xl:mt-0 mt-12 mb-12'>
                                <h2 className='text-4xl xl:text-6xl pl-3 uppercase text-left fontfamily font-semibold' style={{ color: '#DE0A26' }}>
                                    BUILD YOUR  <span className='text-white'>CARRER </span> WITH US
                                </h2>
                            </div>
                            <div className='xl:w-2/12'></div>
                        </div>
                    </div>
                </div>
            </section>








            <section className="container mx-auto xl:px-12 xl:mt-12 mt-12">
                <div className="xl:px-12 lg:px-10 px-2 w-full">
                    <div className="flex mx-auto justify-center">
                        <div className="xl:w-8/12 w-12/12">
                            <h3 className="fontfamily xl:text-2xl text-lg font-semibold text-red-900 text-center" >
                                Join the Archywave Team
                            </h3>
                            <p className="fontfamily text-black mt-4 text-2xl xl:text-xl text-center">
                                Do you want to join a talented team
                            </p>
                            <form onSubmit={handleSubmit} className="xl:mt-6 mt-6">
                                <div className="flex flex-wrap">
                                    <div className="px-2 xl:w-6/12 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="First Name" name="name" maxlength="25" onChange={e => setFirstName(e.target.value)} />
                                        {nameError && firstName.length === 0 ?
                                            <label className="error">Enter Your First Name</label> : ""}
                                    </div>
                                    <div className="px-2 xl:w-6/12 w-12/12">
                                        <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                            placeholder="Last Name" name="namee" maxlength="25" onChange={e => setLastName(e.target.value)} />
                                        {nameeError && lastName.length === 0 ?
                                            <label className="error">Enter Your Last Name</label> : ""}
                                    </div>
                                </div>

                                <div className="px-2 xl:w-12/12 xl:mt-6 w-12/12">
                                    <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                        placeholder="Email" name="email" onChange={e => setEmail(e.target.value) + setEmailRegexError(e.target.value)} />
                                    {emailError && email.length === 0 ?
                                        <label className="error">Enter Your Email</label> : ""}
                                    {emailregexError && (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) ?
                                        <label className="error">Please Enter Valid Email</label> : ""}
                                </div>

                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                    <input className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                        placeholder="Phone Number" name="tel" maxlength="10" onChange={e => setTel(e.target.value) + setTelRegexError(e.target.value)} />
                                    {telError && tel.length === 0 ?
                                        <label className="error pt-2">Enter Your Contact Number</label> : ""}
                                    {telregexError && (!(/^(?:\+?\d{1,3})?0?\d{7,12}$/.test(tel))) ?
                                        <label className="error pt-2">Please Enter Valid Number</label> : ""}
                                </div>
                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">

                                    <select
                                        id="department"
                                        name="department"
                                        value={department}
                                        onChange={(e) => setDepartment(e.target.value)}
                                        className={`xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${departmentError ? 'border-red-500' : ''}`}
                                    >
                                        <option value="">Select your Department</option>
                                        <option value="3D Modeling">3D Modeling</option>
                                        <option value="Accounts">Accounts</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Project Coordinator arctict">Project Coordinator arctict</option>
                                        <option value="CGI Artist">CGI Artist</option>
                                        <option value="3D Profile">3D Profile</option>
                                        <option value="Clients Intractor">Clients Intractor</option>
                                        <option value="Key Account Manager">Key Account Manager</option>
                                    </select>
                                    {departmentError && <p className="error">Please select your department</p>}
                                </div>
                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                    <input
                                        className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                        placeholder="Upload Your Resume" name="resume" type="file" onChange={(e) => setResume(e.target.files)} required
                                    ></input>
                                </div>
                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                    <input
                                        className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base"
                                        placeholder="Portfolio" name="portfolio" type="file" onChange={(e) => setPortfolio(e.target.files)}
                                    ></input>
                                </div>
                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                <select
                                        id="department"
                                        name="department"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        className={`xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${departmentError ? 'border-red-500' : ''}`}
                                    >
                                        <option value="">Select your Country</option>
                                        <option value="3D Modeling">Australia</option>
                                        <option value="Accounts">UAE</option>
                                    </select>
                                    {countryError && <p className="error">Please select your country</p>}
                                </div>
                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                <select
                                        id="department"
                                        name="department"
                                        value={visa}
                                        onChange={(e) => setVisa(e.target.value)}
                                        className={`xl:w-12/12 w-12/12 px-2 border border-lightgrey h-12 rounded mt-2 fontfamily xl:text-base ${departmentError ? 'border-red-500' : ''}`}
                                    >
                                        <option value="">Do need Visa Sponsorship?</option>
                                        <option value="3D Modeling">Yes</option>
                                        <option value="Accounts">No</option>
                                    </select>
                                    {visaError && <p className="error">Please select your visa Sponsorship</p>}
                                </div>



                                <div className="px-2 xl:w-12/12 w-12/12 xl:mt-6">
                                    <textarea className="xl:w-12/12 w-12/12 px-2 border border-lightgrey h-20 rounded mt-2 fontfamily xl:text-base"
                                        placeholder="Message" name="message" onChange={e => setMessage(e.target.value)} />
                                    {messageError && message.length === 0 ?
                                        <label className="error">Please Enter Your Message</label> : ""}
                                </div>
                                <div className="items-center text-center justify-center">
                                    <button className="justify-center text-center px-12 py-2 rounded-lg  bg-red-900 text-white mt-6 fontfamily font-semibold xl:text-base" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </section>


            <Footer />
        </>
    );
}
